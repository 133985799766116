import { imgFullWidth, seoData } from "."

export const newsletterQuery = `
query NewsletterQuery {
  newsletterEntries {
    ... on newsletter_newsletter_Entry {
      img ${imgFullWidth}
      heading
      contentText
      preheading
      ${seoData}
    }
  }
}

`
<template>
    <div :class="props.class" >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.044 24.872" class="animation flip">
        <path id="Pfad_40374" data-name="Pfad 40374"
              d="M19.978,25.958V22.241l-3.763,3.717V22.241L12.5,25.958H.682V16.665L16.216,1.132V16.666L31.726,1.132V26l-11.747-.045"
              transform="translate(-0.682 -1.132)" fill="#EAEAEA" />
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>

<style scoped>
  .animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  @keyframes flip {
    0%   { transform: rotateY(0); }
    10%  { transform: rotateY(36deg); }
    15%  { transform: rotateY(54deg); }
    20%  { transform: rotateY(72deg); }
    25%  { transform: rotateY(90deg); }
    30%  { transform: rotateY(108deg); }
    35%  { transform: rotateY(126deg); }
    40%  { transform: rotateY(144deg); }
    45%  { transform: rotateY(162deg); }
    50%  { transform: rotateY(180deg); }
    55%  { transform: rotateY(198deg); }
    60%  { transform: rotateY(216deg); }
    65%  { transform: rotateY(234deg); }
    70%  { transform: rotateY(252deg); }
    75%  { transform: rotateY(270deg); }
    80%  { transform: rotateY(288deg); }
    85%  { transform: rotateY(306deg); }
    90%  { transform: rotateY(324deg); }
    95%  { transform: rotateY(342deg); }
    100% { transform: rotateY(360deg); }
  }

  @-webkit-keyframes flip {
    0%   { -webkit-transform: rotateY(0); }
    10%  { -webkit-transform: rotateY(36deg); }
    15%  { -webkit-transform: rotateY(54deg); }
    20%  { -webkit-transform: rotateY(72deg); }
    25%  { -webkit-transform: rotateY(90deg); }
    30%  { -webkit-transform: rotateY(108deg); }
    35%  { -webkit-transform: rotateY(126deg); }
    40%  { -webkit-transform: rotateY(144deg); }
    45%  { -webkit-transform: rotateY(162deg); }
    50%  { -webkit-transform: rotateY(180deg); }
    55%  { -webkit-transform: rotateY(198deg); }
    60%  { -webkit-transform: rotateY(216deg); }
    65%  { -webkit-transform: rotateY(234deg); }
    70%  { -webkit-transform: rotateY(252deg); }
    75%  { -webkit-transform: rotateY(270deg); }
    80%  { -webkit-transform: rotateY(288deg); }
    85%  { -webkit-transform: rotateY(306deg); }
    90%  { -webkit-transform: rotateY(324deg); }
    95%  { -webkit-transform: rotateY(342deg); }
    100% { -webkit-transform: rotateY(360deg); }
  }

  .flip {
    transform-origin: bottom;
    margin: 0 auto 0 auto;
    animation-name: flip;
    -webkit-animation-name: flip;
    animation-timing-function: ease-in-out;
  }
</style>
<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.542 12.76">
            <path id="Pfad_40379" data-name="Pfad 40379"
                d="M13.01,9.434l3.762-3.762a.6.6,0,0,1,1.02.422v9.07a.6.6,0,0,1-1.02.422L13.01,11.825M4.043,16.01h7.173a1.793,1.793,0,0,0,1.793-1.793V7.043A1.793,1.793,0,0,0,11.216,5.25H4.043A1.793,1.793,0,0,0,2.25,7.043v7.173A1.793,1.793,0,0,0,4.043,16.01Z"
                transform="translate(-1.25 -4.25)" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-width="2" />
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
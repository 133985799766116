<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M19.5 8.25L12 15.75L4.5 8.25" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.7 19.7">
        <path id="Pfad_40434" data-name="Pfad 40434" d="M12,3a48.277,48.277,0,0,1,8.083.678A1.107,1.107,0,0,1,21,4.774V5.818a2.25,2.25,0,0,1-.659,1.591l-5.432,5.432a2.25,2.25,0,0,0-.659,1.591v2.927a2.25,2.25,0,0,1-1.244,2.013L9.75,21V14.432a2.25,2.25,0,0,0-.659-1.591L3.659,7.409A2.25,2.25,0,0,1,3,5.818V4.774a1.107,1.107,0,0,1,.917-1.1A48.32,48.32,0,0,1,12,3Z" transform="translate(-2.15 -2.15)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
<template>
    <HeroSwiperPagination
        paginationPosition="right"
        class="pb-12 slider-pagination-right normal"
        :slidesPerView="slides"
        :spaceBetween="25"
        :action-link="action"
        :actionButton="true"
    >
        <swiper-slide class="h-auto pb-4 lg:pb-0" v-for="card in items[0].entry">
            <Card
                :title="card.title"
                :url="card?.uri"
                :image="card.houseHeaderImage"
            />
        </swiper-slide>
    </HeroSwiperPagination>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components

import HeroSwiperPagination from "./HeroSwiperPagination.vue";
import { SwiperSlide } from "swiper/vue";
import Card from "./Card.vue";
import { onMounted, ref } from "vue";

import { Link, Referenz } from "@/types/elements";

const slides = ref(0);

onMounted(() => {
    setSlidesValue();
    window.addEventListener("resize", setSlidesValue);
});

const props = defineProps<{
    items: Referenz[];
    action: Link | null;
}>();

function setSlidesValue() {
    if (window.innerWidth >= 1024) {
        slides.value = 2;
    } else if (window.innerWidth >= 768) {
        slides.value = 2.7;
    } else if (window.innerWidth >= 640) {
        slides.value = 2.2;
    } else {
        slides.value = 1.4;
    }
}
</script>

<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.046 20.281">
            <path id="Pfad_40370" data-name="Pfad 40370"
                d="M8.25,21V16.125A1.125,1.125,0,0,1,9.375,15h2.25a1.125,1.125,0,0,1,1.125,1.125V21m0,0h4.5V3.545M12.75,21h7.5V10.75M2.25,21h1.5m18,0h-18M2.25,9l4.5-1.636M18.75,3l-1.5.545m0,6.2,3,1m1.5.5-1.5-.5M6.75,7.364V3h-3V21m3-13.636,10.5-3.819"
                transform="translate(-0.969 -1.719)" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </svg>

    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.322 15.288">
            <path id="Pfad_3363" data-name="Pfad 3363"
                d="M3,15.143V8.422a1.506,1.506,0,0,1,.67-1.253L8.939,3.657a1.505,1.505,0,0,1,1.671,0l5.269,3.512a1.505,1.505,0,0,1,.67,1.253v6.722M3,15.143a1.505,1.505,0,0,0,1.505,1.505H15.043a1.505,1.505,0,0,0,1.505-1.505M3,15.143l5.081-3.387m8.468,3.387-5.081-3.387M3,8.369l5.081,3.387m8.468-3.387-5.081,3.387m0,0-.858.572a1.505,1.505,0,0,1-1.671,0l-.858-.572"
                transform="translate(-1.613 -2.361)" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
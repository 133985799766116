import client from "@/api/craft";
import { getSiteId } from "@/stores/siteid";
import type {Block} from "@/types";

const siteid = getSiteId();

// Store data in localStorage with an expiration date
export function setCache(key: string, value: string, expirationMinutes: number): void {
    key = key + siteid;
    localStorage.removeItem(key)
    const now = new Date();
    const expirationTime = now.getTime() + expirationMinutes * 60 * 1000; // Convert minutes to milliseconds
    const item = {
        value: value,
        expiration: expirationTime
    };
    localStorage.setItem(key, JSON.stringify(item));
}

// Retrieve data from localStorage and check expiration
export function getCache(key): string | null {
    key = key + siteid;
    const item = localStorage.getItem(key);
    if (!item) {
        return null; // Key not found or expired
    }
    const parsedItem = JSON.parse(item);
    const now = new Date();
    if (now.getTime() > parsedItem.expiration) {
        localStorage.removeItem(key); // Remove expired data
        return null; // Data expired
    }
    return parsedItem.value; // Return valid data
}
// Sync data in localStorage with the server
export async function syncDatainStorage(key: string, state: any, handle: string, section: string = "default"): Promise<void> {
    key = key + siteid;
    const localState = JSON.parse(getCache(key)) as Block[];

    if(localState === null) {
        state.value = await fetchData(handle, section);
    } else {
        state.value = localState;
    }
}

let key = "ksdfkjksjdfkjksdf";


// compare function from chat gpt
function deepEqual(obj1: Object, obj2: Object): boolean {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        // @ts-ignore
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }
    return true;
}
// Fetch data from the server
async function fetchData(handle: string, section: string): Promise<any> {
    if (handle === "navigation") {
        let res = await client.GetNavigation();
        const dataNav = {
            navigation: res.data.globalSet.navigation,
            staticLinks: res.data.globalSet.statikLinks
        }
        return dataNav;
    }
    else if (handle === "footer") {
        let res = await client.GetFooter();
        const data = {
            footer: res.data.globalSet.footerNavigation,
            footerService: res.data.globalSet.footerServiceNavigation,
            logo: res.data.globalSet.footerLogo
        }
        return data;
    } 
    else {
        const res = await client.GetPageByHandle(handle, section);
        const entry = res.data.entries.filter((entry: any) => Object.keys(entry).length > 0);
        return entry.length > 0 ? entry[0].blocks : null;
    }
}

<template>
    <div class="flex justify-center marginBottom-standard mt-24 md:mt-48 2xl:mb-48 2xl:mt-60">
      <HouseAnimation class="w-24"/>
    </div>
</template>

<script setup lang="ts">

import { onMounted } from 'vue';
import { loaded } from "@/stores/loading";
import HouseAnimation from "@/icons/HouseAnimation.vue";

onMounted(() => {
    loaded.value.animation = true;
    setTimeout(() => {
        loaded.value.active = false;
    }, 300);
});

</script>
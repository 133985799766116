<template>
  <div class="flex gap-5 flex-col">
    <div class="space-y-4 pb-4" v-if="consultant && consultant.firstname && consultant.lastname">
      <div>
        <p class="text-sm leading-5 mt-3" v-if="consultant && consultant.firstname && consultant.lastname">
          <a v-if="consultant && consultant.consultantPageLink && consultant.consultantPageLink[0]" class="group" :href="consultant.consultantPageLink[0].url ? consultant.consultantPageLink[0].url: '/'" target="_blank">
                    <span class="h4 group-hover:underline underline-offset-[3px]">
                      {{consultant.firstname}}</span> <span class="h4 group-hover:underline underline-offset-[3px]">{{ consultant.lastname }}<ArrowTopOnSquare class="w-4 ml-1 [&_svg]:w-4 [&_svg]:vertical [&_svg]:inline [&_path]:!stroke-1.5 inline vertical [&_svg]:group-hover:scale-110 [&_svg]:align-top" />
                    </span>
          </a>
          <span v-else class="h4">{{consultant.firstname + " " + consultant.lastname }}</span>
          <br v-if="consultant.title_consultant">{{consultant.title_consultant ? consultant.title_consultant : '' }}
        </p>
      </div>
      <div v-if="consultant && consultant.phoneNumber">
        <a :href="`tel:${consultant.phoneNumber}`" class="group flex items-center gap-2">
          <span class="block w-4">
              <PhoneReceiver class="w-4 [&_path]:!stroke-1.5" />
          </span>
          <span class="font-bold group-hover:underline underline-offset-[3px]">{{ consultant.phoneNumber }}</span>
        </a>
      </div>
      <div v-if="consultant && consultant.mobilePhone">
        <a :href="`tel:${consultant.mobilePhone}`" class="group flex items-center gap-2">
          <span class="block w-4">
              <Phone class="w-4 [&_path]:!stroke-1.5" />
          </span>
          <span class="font-bold group-hover:underline underline-offset-[3px]">{{ consultant.mobilePhone }}</span>
        </a>
      </div>
      <div v-if="consultant && consultant.emailAddress">
        <a :href="`mailto:${consultant.emailAddress}`" class="group flex items-center gap-2">
          <span class="block w-4">
              <Email class="w-4 [&_path]:!stroke-1" />
          </span>
          <span class="font-bold group-hover:underline underline-offset-[3px]">{{ consultant.emailAddress }}</span>
        </a>
      </div>
      <div v-if="consultant && consultant.id">
        <button type="button"
                @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Online'; consultantStore.houseStyle = houseStyle"
                class="group flex items-center gap-2">
          <span class="block w-4">
              <Camera class="w-4 [&_path]:!stroke-1" />
          </span>
          <span class="font-bold group-hover:underline underline-offset-[3px]">{{ getTranslationByKey('Online-Video-Beratung') }}</span>
        </button>
      </div>
      <div v-if="consultant && consultant.id">
        <button type="button"
                @click="consultantStore.active = true; consultantStore.id = consultant.id; consultantStore.type = 'Vor Ort'; consultantStore.houseStyle = houseStyle"
                class="group flex items-center gap-2">
          <span class="block w-4">
              <Tag class="w-4 [&_path]:!stroke-1" />
          </span>
          <span class="font-bold group-hover:underline underline-offset-[3px]">{{ getTranslationByKey('Beratungstermine vor Ort') }}</span>
        </button>
      </div>
    </div>
    <template v-if="!isCurrentEnglish()">
      <div v-if="houseStyles"
          class="border-2 border-black rounded-full minimal-input transition-[border] [&:has(input:focus-visible)]:border-primary">
        <div class="border-2 border-white rounded-full minimal-input">
          <SmallSelect :chips="false" :hideDetails="false" :clearable="false"
                       :closableChips="false" :label="`${getTranslationByKey('Bitte wählen')}…`"
                       :placeholder="`${getTranslationByKey('Bitte wählen')}…`"
                       :items="houseStyles.map(entry => { return { title: entry.title, value: entry.id } })"
                       :multiple="false" @update="(e: number) => houseStyle = e.toString()"/>
        </div>
      </div>
      <div v-else class="skeleton rounded-full h-11 w-full"></div>
      <div v-if="houseStyles"
          class="flex border-2 bg-white border-black rounded-full minimal-input transition-[border] [&:has(input:focus-visible)]:border-primary [&:has(input:focus-visible)_button]:bg-primary">
        <v-text-field v-model="zipCode" type="number" bgColor="color"
                      base-color="#fff"  :rules="[(value: string) => value.length <= 5]"
                      class="w-[55%] no-focus-visible"
                      hide-spin-buttons single-line :label="`${getTranslationByKey('PLZ-Suche')}`"></v-text-field>
        <v-btn-inverted @click="searchConsultant" :rules="[zipCode.length == 5]" :disabled="zipCode.length != 5"  class="outline-hover">{{ getTranslationByKey('Berater finden') }}</v-btn-inverted>
      </div>
      <div v-else class="skeleton rounded-full h-11 w-full"></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import Phone from '@/icons/Phone.vue'
import Email from '@/icons/Email.vue'
import Tag from '@/icons/Tag.vue'
import Camera from '@/icons/Camera.vue'
import {ref} from 'vue';
import {Consultant, ConsultantFooter, ConsultantSearch} from "@/types";
import Avatar from "@/icons/Avatar.vue";

const emit = defineEmits(['input'])
const props = defineProps(["image", "color"])


import SmallSelect from '@/components/Select/SmallSelect.vue';

import consultantStore from "@/stores/consultant";
import client from "@/api/craft";
import errorStore from "@/stores/error";
import {useRoute} from "vue-router";
import PhoneReceiver from "@/icons/PhoneReceiver.vue";
import ArrowTopOnSquare from "@/icons/ArrowTopOnSquare.vue";
import {getTranslationByKey} from "@/stores/translation";
import {isCurrentEnglish} from "@/stores/siteid";

const route = useRoute();
const consultant = ref<null | Consultant>(null);

const houseStyles = ref([]);
const houseStyle = ref('');

const zipCode = ref('');

const items = [
  {
    title: "Phone",
    icon: Phone,
    link: "05151 / 9538-0"
  },
  {
    title: "Email",
    icon: Email,
    link: "info@meisterstueck.de"
  },
  {
    title: "Tag",
    icon: Tag,
    link: "Beratungstermine vor Ort"
  },
  {
    title: "Camera",
    icon: Camera,
    link: "Online-Video-Beratung"
  }
]

if (route.params?.pathMatch && route.params?.pathMatch.length > 0) {
  // last slug for pages
  client.GetDefaultConsultant(route.params.pathMatch[route.params.pathMatch.length - 1]).then((res: {
    data: ConsultantFooter
  }) => {
    ValidateResponse(res);
  });
} else if (route.name == 'House') {
  // get consultant for house
  client.GetDefaultConsultant(route.params.slug.toString()).then((res: { data: ConsultantFooter }) => {
    ValidateResponse(res);
  });
} else {
  client.GetDefaultConsultant(route.path == '/' ? 'startseite' : 'default').then((res: { data: ConsultantFooter }) => {
    ValidateResponse(res);
  });
}

function searchConsultant() {
  if(zipCode.value.length <= 5 && houseStyle.value.length > 0) {
    client.GetConsultant(zipCode.value, houseStyle.value, (data: ConsultantSearch) => {
      consultant.value = {
        ...data.consultant,
        isDefault: true
      };

      if (!data.success) {
        errorStore.value.message = data.errors.join(', ');
        errorStore.value.active = true;
      }
    })
  }
}

function ValidateResponse(res: any) {
  if (res.data.entries && res.data.entries.length > 0 && res.data.entries[0].relationConsultantHouses !== undefined  && res.data.entries[0].relationConsultantHouses.length > 0) {
    consultant.value = res.data.entries[0].relationConsultantHouses[0];
    consultant.value['isDefault'] = false;
  } else {
    consultant.value = res.data.globalSets[0].consultant[0];
    consultant.value['isDefault'] = true;
  }

  houseStyles.value = res.data.categories;
}
</script>
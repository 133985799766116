<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.999 23.999">
        <g id="Gruppe_2147" data-name="Gruppe 2147" transform="translate(0.5 0.5)">
          <path id="Schnittmenge_1" data-name="Schnittmenge 1" d="M8.095,22.486a12.837,12.837,0,0,0,1.429-3.015c.14-.538.717-2.733.717-2.733a3.078,3.078,0,0,0,2.636,1.32c3.468,0,5.967-3.19,5.967-7.153,0-3.8-3.1-6.642-7.089-6.642-4.963,0-7.6,3.332-7.6,6.959,0,1.686.9,3.786,2.334,4.454.218.1.334.057.385-.154.038-.159.231-.941.32-1.3a.344.344,0,0,0-.08-.33,4.476,4.476,0,0,1-.855-2.625,4.977,4.977,0,0,1,5.192-4.99,4.54,4.54,0,0,1,4.8,4.678c0,3.111-1.571,5.266-3.615,5.266a1.67,1.67,0,0,1-1.7-2.078,23.165,23.165,0,0,0,.952-3.829,1.445,1.445,0,0,0-1.455-1.62c-1.154,0-2.081,1.194-2.081,2.793A4.154,4.154,0,0,0,8.7,13.193s-1.139,4.818-1.348,5.716a11.017,11.017,0,0,0-.04,3.3,11.223,11.223,0,1,1,.786.279Zm-.786-.279Z" transform="translate(0 0)" fill="currentColor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
        </g>
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
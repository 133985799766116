<template>
  <section class="full hero full-end layout">
    <div
      class="full full-end lg:full relative lg:full-end lg:z-[15] aspect-[3.7] lg:aspect-[5.7]"
    >
      <div
        class="absolute full h-full full-end top-0 left-0 w-full after:absolute after:w-full after:rounded-lg after:h-full after:top-0 after:left-0 after:bg-gradient-to-t after:from-black after:opacity-50 after:z-5"
      >
        <Image
          class="w-full h-full opacity-90 rounded-lg object-cover"
          src="/images/BV-HR-5828.jpg"
        />
      </div>
      <div
        class="relative z-10 text-white text-center h-full flex items-center justify-center py-12 md:py-20 lg:py-24 xl:py-10"
      >
        <h1 class="h1">{{ getTranslationByKey('Ihre persönliche Merkliste') }}</h1>
      </div>
    </div>
  </section>
  <section
    class="layout full full-end marginTop-small marginBottom-small content pt-4 pb-10"
  >
    <p class="lg:max-w-[70%]" v-html="getTranslationByKey('Wunschliste Einleitung')"></p>
  </section>
  <section>
    <div v-if="items && items.length > 0" class="grid grid-cols-1 gap-16 lg:gap-6">
      <div
        v-for="item in items"
        style="--gap-small: 0.75rem; --gap: 2.75rem"
        class="relative animate-fastFade z-0 flex flex-wrap justify-between gap-[var(--gap-small)] bg-gray p-2 pb-8 lg:p-4 rounded-lg [&_picture]:w-[25%] [&_picture]:lg:w-[15%]"
      >
        <router-link
          class="w-[calc(25%-var(--gap-small))] lg:w-[calc(17%-var(--gap))]"
          v-if="item.uri"
          :to="item.uri"
        >
          <Image
            v-if="item.houseHeaderImage[0]"
            :src="item.houseHeaderImage[0].url"
            :srcset="item.houseHeaderImage[0].srcset"
            :alt="item.houseHeaderImage[0].alt ? item.houseHeaderImage[0].alt : ''"
            :title="item.houseHeaderImage[0].title ? item.houseHeaderImage[0].title : ''"
            class="aspect-[1.25] rounded-lg object-cover"
          />
        </router-link>
        <template v-else>
          <Image
            v-if="item.houseHeaderImage[0]"
            :src="item.houseHeaderImage[0].url"
            :srcset="item.houseHeaderImage[0].srcset"
            :alt="item.houseHeaderImage[0].alt ? item.houseHeaderImage[0].alt : ''"
            :title="item.houseHeaderImage[0].title ? item.houseHeaderImage[0].title : ''"
            class="aspect-[1.25] rounded-lg object-cover"
          />
        </template>
        <div
          class="w-[calc(75%-var(--gap-small))] lg:w-[calc(68%-var(--gap))] lg:space-y-4 py-4"
        >
          <router-link v-if="item.uri" :to="item.uri" class="hover:text-primary">
            <h3 v-text="item.title"></h3>
          </router-link>
          <h3 v-else v-text="item.title"></h3>
          <ul class="w-full lg:flex flex-wrap gap-2 hidden">
            <li
              v-if="item.houseStyles && item.houseStyles[0]"
              class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
            >
              {{ item.houseStyles[0].title }}
            </li>
            <li
              v-if="item.realEstateTypes && item.houseTypes[0]"
              class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
            >
              {{ item.houseTypes[0].title }}
            </li>
            <li
              v-if="item.totalLivingArea && item.totalLivingArea > 0"
              class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
            >
              {{ item.totalLivingArea }} m²
            </li>
            <li
              v-if="item.energeticStandards && item.energeticStandards[0]"
              class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
            >
              {{ item.energeticStandards[0].title }}
            </li>
          </ul>
        </div>
        <ul class="w-full flex flex-wrap gap-2 lg:hidden">
          <li
            v-if="item.houseStyles && item.houseStyles[0]"
            class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
          >
            {{ item.houseStyles[0].title }}
          </li>
          <li
            v-if="item.realEstateTypes && item.houseTypes[0]"
            class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
          >
            {{ item.houseTypes[0].title }}
          </li>
          <li
            v-if="item.totalLivingArea && item.totalLivingArea > 0"
            class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
          >
            {{ item.totalLivingArea }} m²
          </li>
          <li
            v-if="item.energeticStandards && item.energeticStandards[0]"
            class="bg-white py-1 px-2 rounded-full grid place-content-center text-sm"
          >
            {{ item.energeticStandards[0].title }}
          </li>
        </ul>
        <div
          class="flex gap-2 items-center absolute bottom-0 right-0 translate-y-1/2 lg:translate-y-0 lg:w-max lg:flex-col lg:items-end lg:justify-between lg:static lg:w-[calc(15%-var(--gap))]"
        >
          <v-btn
            @click="RemoveFromWishlist(item.id)"
            :rounded="true"
            class="!p-0 w-10 !h-10 !min-w-10"
          >
            <Trash class="w-5 stroke-2"></Trash>
          </v-btn>
          <v-btn :href="item.url" :text="getTranslationByKey('Infos und Kontakt')"></v-btn>
        </div>
      </div>
    </div>
    <div
      v-else-if="wishlist && wishlist.wishlistItems.length > 0"
      class="grid grid-cols-1 gap-4 lg:gap-6"
    >
      <div
        v-for="item in wishlist.wishlistItems.length"
        style="--gap: 2rem"
        class="relative z-0 flex flex-wrap justify-between gap-[var(--gap)] p-2 pb-8 lg:p-4 rounded-lg"
      >
        <div
          class="aspect-[1.25] w-[calc(25%-var(--gap))] lg:w-[calc(17%-var(--gap))] rounded-lg object-cover skeleton"
        />
        <div
          class="w-[calc(75%-var(--gap))] lg:w-[calc(68%-var(--gap))] lg:space-y-4 py-4"
        >
          <h3 class="skeleton w-1/3 h-4"></h3>
          <ul class="w-full lg:flex flex-wrap gap-2 hidden">
            <li
              v-for="i in 6"
              class="bg-white py-2 px-6 rounded-full grid place-content-center text-sm skeleton"
            ></li>
          </ul>
        </div>
        <ul class="w-full flex flex-wrap gap-2 lg:hidden">
          <li
            v-for="i in 6"
            class="bg-white py-2 px-6 rounded-full grid place-content-center text-sm skeleton"
          ></li>
        </ul>
        <div
          class="flex gap-2 items-center absolute bottom-0 right-0 translate-y-1/2 lg:translate-y-0 lg:flex-col lg:items-end lg:justify-between lg:static lg:w-[calc(15%-var(--gap))]"
        >
          <div class="skeleton w-8 aspect-square rounded-full"></div>
          <div class="skeleton w-20 p-4"></div>
        </div>
      </div>
    </div>
    <div v-else class="pb-8">
      <p class="content" v-html="getTranslationByKey('Sie haben noch keine Meisterstücke')"></p>
    </div>
    <div class="flex justify-center mt-16 lg:mt-10">
      <router-link :to="isCurrentEnglish() ? '/house-designs' : '/hausfinder'">
        <v-btn>{{ getTranslationByKey('mehr Häuser hinzufügen') }}</v-btn>
      </router-link>
    </div>
  </section>
  <!--    <section class="relative marginTop-standard marginBottom-standard">-->
  <!--      <Newsletter block="newsletter" />-->
  <!--      <newsletter-offcanvas v-if="active" @close="active = false" :email :active/>-->
  <!--    </section>-->
</template>

<script setup lang="ts">
import wishlist from "@/stores/wishlist";
import client from "@/api/craft";
import { ref } from "vue";
import { watch, onMounted } from "vue";
import Image from "@/snippets/Image.vue";
import Trash from "@/icons/Trash.vue";
import { loaded } from "@/stores/loading";
import { useHead } from "@unhead/vue";
import { seoData } from "@/api/queries";
import {getTranslationByKey} from "@/stores/translation";
import {isCurrentEnglish} from "@/stores/siteid";
// import Newsletter from "@/components/ActionBaner/Newsletter.vue";
// import NewsletterOffcanvas from "@/components/ActionBaner/components/NewsletterOffcanvas.vue";

const items = ref([]);

const seo = ref(<seoData>{
  metaTitle: "Wunschliste | Meisterstück-HAUS",
  metaDescription:
    "Hier finden Sie die Auswahl Ihrer ausgewählten Meisterstücke. Nutzen Sie jetzt die Möglichkeit, Ihre Hauswahl weiter zu erweitern.",
  globalMetaImage: {
    url: "/files/images/SEO/MS-logo.jpg",
    width: "322px",
    height: "139px",
    format: "jpg",
  },
});

// const active = ref(false);
// const email = ref("");

watch(
  () => wishlist.value,
  (value) => {
    if (value.wishlistId !== 0) {
      client.getWishlistItemsByIds(value.wishlistItems).then((res) => {
        if (res) {
          items.value = res.data.houseEntries;
          console.log(items.value);
        }
      });
    }
  }
);

AccessEntries();

function RemoveFromWishlist(id: string) {
  client.RemoveFromWihlist(id).then((res) => {
    if (res) {
      wishlist.value = {
        wishlistId: wishlist.value.wishlistId,
        wishlistItems: wishlist.value.wishlistItems.filter((item: any) => item !== id),
      };
      AccessEntries();
    }
  });
}

function AccessEntries() {
  if (wishlist.value.wishlistItems.length === 0) {
    items.value = [];
  } else {
    client.getWishlistItemsByIds(wishlist.value.wishlistItems).then((res) => {
      if (res) {
        items.value = res.data.houseEntries;
      }
    });
  }
}
getSEOData();
onMounted(() => {
  if (wishlist.value.wishlistId !== 0) {
    client.getWishlistItemsByIds(wishlist.value.wishlistItems).then((res) => {
      if (res) {
        items.value = res.data.houseEntries;
      }
    });
  }
  loaded.value = false;
});

async function getSEOData() {
  let dataResults = await client.GetWishlistPage();
  let globalData = await client.GetGeneralGlobals();

  if (dataResults && dataResults.data.entries.length > 0) {
    seo.value = {
      metaTitle: dataResults.data.entries[0].metaTitle ?? seo.value.metaTitle,
      metaDescription:
        dataResults.data.entries[0].metaDescription ?? seo.value.metaDescription,
      metaImage: dataResults.data.entries[0].metaImage,
    };
  }
  if (globalData && globalData.data.globalSet && globalData.data.globalSet.metaImage) {
    seo.value.globalMetaImage = globalData.data.globalSet.metaImage[0];
  }
}

useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format
          : seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format,
    },
    {
      property: "og:image:width",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width
          : seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width,
    },
    {
      property: "og:image:height",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height
          : seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height,
    },
    {
      name: "twitter:image",
      content: () =>
        seo.value &&
        seo.value.metaImage &&
        seo.value.metaImage[0] &&
        seo.value.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>

<template>
  <div
      class="absolute z-50 md:z-30 md:relative w-[100vw] md:w-max md:max-w-[400px] h-[100dvh]  top-0 bg-darkgray px-6 lg:px-10 pb-6 py-24 lg:pt-[6.5rem] space-y-6 md:space-y-0 transition-[left] duration-300 rounded-r-md shadow-sm"
      :class="props.state.active ? 'left-0 pls-no-scroll' : '-left-full'">
    <div>
      <button type="button" @click="() => emit('close')" class="flex items-center gap-4 md:hidden">
        <BackArrow class="w-5"/>
        <span class="font-bold">zurück</span>
      </button>
    </div>
    <div>
      <ul v-if="props.state.links && props.state.links.length > 0" class="flex flex-col gap-5 font-semi-bold text-lg">
        <li v-for="link in props.state.links" class="hover:text-primary w-max">
          <router-link active-class="text-primary" v-if="link.navLink[0].url || link.navLink[0].element.uri"
                       @click="emit('linkClicked')"
                       :to="link.navLink[0].isElement ? FormatLink(link.navLink[0].element?.uri) : link.navLink[0].url">
            {{ link.heading ? link.heading : link.navLink[0].element?.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {NavigationItem} from "@/types";
import BackArrow from '@/icons/BackArrow.vue'
import {watch} from "vue";
import {FormatLink} from "@/util/formater";


const props = defineProps<{
  state: {
    active: boolean,
    links: NavigationItem[]
  }
}>();

const emit = defineEmits(["close", "linkClicked"]);
</script>
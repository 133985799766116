<template>
  <section>
    <div class="flex flex-col items-center justify-center">
      <img class="object-contain h-auto max-h-[480px] aspect-video w-max rounded-lg mb-4"
           :src="(data.appointmentImage[0].url ?? data.appointmentImage[0].orgUrl)"
           :srcset="data.appointmentImage[0].srcset"
           :alt="data.appointmentImage[0].alt ? data.appointmentImage[0].alt : ''"
           :title="data.appointmentImage[0].title ? data.appointmentImage[0].title : ''"
           :style="` ${ data.appointmentImage[0]?.hasFocalPoint ? `object-position: ${ data.appointmentImage[0]?.focalPoint.map(entry => (entry * 100).toFixed(2).toString() + '% ' ).join(' ') }` : '' } `"
           v-if="data.appointmentImage && data.appointmentImage[0] && (data.appointmentImage[0].url || data.appointmentImage[0].orgUrl)">
      <h2 v-if="data.title" :class="`text-primary h2-s order-2 my-1`">{{ data.title }}</h2>
      <h3 v-if="data.begin" class="uppercase order-1" >{{ getDate(data.begin) + (data.end ? ' - ' + getDate(data.end) : '') }}</h3>
      <p class="mb-8 order-3"><span v-for="occasion in data.appointmentOccasions">{{ occasion.title }}</span></p>
      <div class="order-4 layout layout-content-start layout-content-end lg:full lg:full-end bg-gray rounded-lg py-7 px-0 lg:py-10 lg:px-4 2xl:px-6 2xl:grid-cols-[3%_1fr_3%]">
        <div ref="content" class="layout-content-start layout-content-end 2xl:col-end-3 text-gray-500 content [&_h2]:h2-s [&_h1]:h1-s [&_h2]:text-primary [&_h1]:text-primary [&_p]:!mt-2 mx-auto" v-html="data.appointmentDescription"></div>
      </div>
      <v-btn v-if="data.appointmentLink[0] && !data.appointmentLink[0].isEmpty && data.appointmentLink[0].element && data.appointmentLink[0].element?.uri"
             :to="(data.appointmentLink[0].isElement ? FormatLink(data.appointmentLink[0].element.uri) : data.appointmentLink[0].element.url)+((data.mailSubject && data.mailSubject[0] && data.mailSubject[0].mailSubject) ? '?subject='+data.mailSubject[0].mailSubject : '')"
             :target="data.appointmentLink[0].target"
             class="mt-4 order-5">{{data.appointmentLink[0].text ? data.appointmentLink[0].text : data.appointmentLink[0].element?.title }}</v-btn>
      <v-btn v-else-if="data.appointmentLink[0] && !data.appointmentLink[0].isEmpty && data.appointmentLink[0].url"
             :href="data.appointmentLink[0].url+((data.mailSubject && data.mailSubject[0] && data.mailSubject[0].mailSubject) ? '?subject='+data.mailSubject[0].mailSubject : '')" :target="data.appointmentLink[0].target"
             class="mt-4 order-5">{{data.appointmentLink[0].text ? data.appointmentLink[0].text : data.appointmentLink[0].element?.title }}</v-btn>
    </div>
  </section>
</template>

<script setup lang="ts">
import moment from "moment";
import {FormatLink} from "@/util/formater";

const props = defineProps<{
  data: any;
}>();

console.log(props.data);

function getDate(data: any) {
  if(data.date != null) {
     return moment(data.date).format('DD.MM.YY');
   } else {
     return moment(data).format('DD.MM.YY');
   }
}
</script>
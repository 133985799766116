<template>
    <v-select
        :hide-details="true"
        :chips="props.chips"
        :multiple="props.multiple"
        :label="props.label"
        color="primary"
        :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
        ]"
    >
        <template v-slot:append-inner="{ isFocused }">
            <div
                class="w-7 transition-transform duration-300"
                :class="isFocused.value ? 'rotate-180' : 'rotate-0'"
            >
                <ChevronDownIcon class="w-full text-primary" />
            </div>
        </template>
        <template v-slot:chip="{ item, index }">
            <span class="bg-gray rounded-full px-2" v-if="index < 2">{{
                item.title
            }}</span>
            <span
                v-if="index === 2"
                class="text-grey text-xl align-self-center"
            >
                ...
            </span>
        </template>
    </v-select>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const props = defineProps<{
    chips: boolean;
    hideDetails: boolean;
    clearable: boolean;
    closableChips: boolean;
    label: string;
    multiple: boolean;
    items: string[];
}>();
</script>

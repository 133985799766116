<template>
  <video v-if="videos && videos.length > 0" :class="props.class" :muted="muted" :autoplay="autoplay" :loop="loop">
    <source v-for="video in videos" :src="origin + video.url" :type="'video/' + video.format">
    Your browser does not support the video tag.
  </video>
</template>

<script setup lang="ts">

const props = defineProps<{
  class: string;
  muted: boolean;
  autoplay: boolean;
  loop: boolean;
  videos?: any[];
}>();

const origin = location.origin;
// const origin = 'https://meisterstueck.menatwork-preview.de';
</script>
<template>
  <section class="full hero full-end layout">
    <div
      class="full full-end lg:full relative lg:full-end lg:z-20 aspect-[3.7] lg:aspect-[5.7]"
    >
      <div
        class="absolute full h-full full-end top-0 left-0 w-full after:absolute after:w-full after:rounded-lg after:h-full after:top-0 after:left-0 after:bg-gradient-to-t after:from-black after:opacity-50 after:z-5"
      >
        <Image
          v-if="page?.img && page?.img.length > 0 && page?.img[0]"
          class="w-full h-full opacity-90 rounded-lg object-cover"
          :src="page.img[0].url"
          :alt="page.img[0].title"
          :title="page.img[0].title"
          :srcset="page.img[0].srcset"
          :isNotLazy="true"
        />
      </div>
      <div
        class="relative z-10 text-white text-center h-full flex items-center justify-center py-12 md:py-20 lg:py-24 xl:py-10"
      >
        <div v-if="loading" class="flex flex-col w-full gap-4 max-w-[600px]">
          <div class="skeleton w-full h-6 rounded-lg"></div>
        </div>
        <div class="flex flex-col" v-else>
          <p
            class="h3 uppercase mt-2 order-1"
            v-if="page?.preheading"
            v-text="page?.preheading"
          ></p>
          <h1 class="h1 order-2" v-if="page?.heading" v-html="page.heading"></h1>
        </div>
      </div>
    </div>
  </section>
  <section
    class="full full-end layout mt-[-10px] marginBottom-standard bg-gray py-4 rounded-b-lg"
  >
    <div class="content mt-[10px]">
      <div v-if="loading" class="flex flex-col w-full gap-4 max-w-[600px]">
        <div class="skeleton w-full h-20 rounded-lg"></div>
      </div>
      <template v-else>
        <p class="!mt-0" v-if="page?.contentText" v-html="page.contentText"></p>
      </template>
    </div>
  </section>
  <section class="bg-gray pt-0 p-6 rounded-lg">
    <h2 class="h2 text-primary">{{ getTranslationByKey('Jetzt anmelden') }}</h2>
    <form id="newsletter-form" class="grid grid-cols-2 gap-6">
      <div class="col-start-1 col-end-2 bg-white rounded-full text-primary">
        <SmallSelect
            :items="[`${getTranslationByKey('Herr')}`, `${getTranslationByKey('Frau')}`, `${getTranslationByKey('Divers')}`]"
            :label="`${getTranslationByKey('Anrede')}`"
            bg-color=""
          @update="data.salutation = $event"
        />
      </div>
      <div class="col-start-1 col-end-4 md:col-end-2 minimal-input">
        <v-text-field single-line :label="`${getTranslationByKey('Vorname')}`" v-model="data.firstName"></v-text-field>
      </div>
      <div class="col-start-1 md:col-start-2 col-end-4 md:col-end-3 minimal-input">
        <v-text-field single-line :label="`${getTranslationByKey('Nachname')}`" v-model="data.lastName"></v-text-field>
      </div>
      <div class="col-start-1 col-end-4 md:col-end-2 minimal-input">
        <v-text-field
          single-line
          :label="`${getTranslationByKey('Telefonnummer')}`"
          v-model="data.phoneNumber"
        ></v-text-field>
      </div>
      <div class="col-start-1 md:col-start-2 col-end-4 md:col-end-3 minimal-input">
        <v-text-field
          :rules="[rules.required]"
          type="email"
          single-line
          :label="`${getTranslationByKey('E-Mail')}*`"
          v-model="data.email"
        ></v-text-field>
      </div>
      <div class="col-start-1 col-end-2 minimal-input">
        <v-text-field single-line :label="`${getTranslationByKey('PLZ')}`" v-model="data.zip"></v-text-field>
      </div>
      <div class="col-start-2 col-end-4 md:col-end-3 minimal-input">
        <v-text-field single-line :label="`${getTranslationByKey('Ort')}`" v-model="data.city"></v-text-field>
      </div>
      <div class="col-start-1 col-end-4 minimal-input">
        <p class="content text-xs pb-3 pl-2" v-html="getTranslationByKey('Formular Datenschutz kurz')"></p>
        <v-btn
          :disabled="!data.email"
          @click="HandleSubmit"
          color="primary"
          dark
          block
          rounded
          class="!min-w-max"
        >
          {{ getTranslationByKey('Absenden') }}
        </v-btn>
      </div>
      <input type="hidden" name="ngst" v-model="data.honeypot" />
    </form>
    <div id="newsletter-success" class="py-4 hidden">
      <h3 class="h2-s text-primary">{{ getTranslationByKey('Vielen Dank für Ihre Anmeldung!') }}</h3>
      <p class="font-bold" v-html="getTranslationByKey('Noch ein Schritt und Sie erhalten regelmäßig wertvolle News und Tipps')"></p>
      <p v-html="getTranslationByKey('Bitte bestätigen Sie nun noch kurz Ihre Anmeldung')"></p>
    </div>
    <div id="newsletter-failed" class="py-4 hidden">
      <h3 class="h2-s text-primary">{{ getTranslationByKey('Registrierung hat nicht geklappt!') }}</h3>
      <p class="font-bold">{{ getTranslationByKey('Bei der Registrierung ist schiefgelaufen:') }}</p>
      <p id="newsletter--error" class="content py-4"></p>
      <v-btn @click="handleBackButton" color="primary" dark rounded class="!min-w-max">{{ getTranslationByKey('Zurück') }}</v-btn
      >
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import craft from "@/api/craft";
import { loaded } from "@/stores/loading";
import { seoData } from "@/api/queries";
import client from "@/api/craft";
import { useHead } from "@unhead/vue";
import {getTranslationByKey} from "@/stores/translation";

const page = ref();
const loading = ref(true);

const data = ref({
  salutation: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  zip: "",
  city: "",
  honeypot: "",
});

const seo = ref(<seoData>{
  metaTitle: "Newsletter | Meisterstück-HAUS",
  metaDescription:
    "Seien Sie immer auf dem Laufenden! Abonnieren Sie unseren Newsletter!",
  globalMetaImage: {
    url: "/files/images/SEO/MS-logo.jpg",
    width: "322px",
    height: "139px",
    format: "jpg",
  },
});

onMounted(() => {
  loading.value = true;
});

function HandleSubmit() {
  craft.SubscribeToNewsletter(data.value);

  if(window._paq) {
    // Set a custom variable before tracking the goal
    window._paq.push(['setCustomVariable',
      3, // Index: A slot from 1 to 5
      'Newsletter-Anmeldung', // Name of the variable
      'Anmeldung per Unterseite', // Value of the variable (e.g., the page title or form name)
      'page' // Scope: "visit" for the visitor's session or "page" for page views
    ]);
    window._paq.push(['trackGoal', 4]);
  }
}

function handleBackButton() {
  document.getElementById("newsletter-form").classList.toggle("!hidden");
  document.getElementById("newsletter-failed").classList.toggle("hidden");
}

const rules = {
  required: (value) => !!value || getTranslationByKey('Pflichtfeld'),
  min: (v) => v.length >= 8 || getTranslationByKey('Ihr Passwort sollte mindestens'),
};

getSEOData();
onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

async function getSEOData() {
  let dataResults = await client.GetNewsletterPage();
  let globalData = await client.GetGeneralGlobals();

  page.value = dataResults.data.newsletterEntries[0];
  loading.value = false;

  if (dataResults && dataResults.data.newsletterEntries.length > 0) {
    seo.value = {
      metaTitle: dataResults.data.newsletterEntries[0].metaTitle ?? seo.value.metaTitle,
      metaDescription:
        dataResults.data.newsletterEntries[0].metaDescription ??
        seo.value.metaDescription,
      metaImage: dataResults.data.newsletterEntries[0].metaImage,
    };
  }
  if (globalData && globalData.data.globalSet && globalData.data.globalSet.metaImage) {
    seo.value.globalMetaImage = globalData.data.globalSet.metaImage[0];
  }
}

useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format
          : seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format,
    },
    {
      property: "og:image:width",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width
          : seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width,
    },
    {
      property: "og:image:height",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height
          : seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height,
    },
    {
      name: "twitter:image",
      content: () =>
        seo.value &&
        seo.value.metaImage &&
        seo.value.metaImage[0] &&
        seo.value.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>

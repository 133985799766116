<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.327 15.683">
            <g id="Gruppe_5491" data-name="Gruppe 5491" transform="translate(-607 -49)">
                <g id="Ellipse_16" data-name="Ellipse 16" transform="translate(607 49)" fill="none" stroke="#1b2125"
                    stroke-linecap="round" stroke-width="2">
                    <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
                    <circle cx="6.5" cy="6.5" r="5.5" fill="none" />
                </g>
                <path id="Pfad_3280" data-name="Pfad 3280" d="M-8776.57-17255.594l4.7,4.564"
                    transform="translate(9394.785 17314.297)" fill="none" stroke="#1b2125" stroke-linecap="round"
                    stroke-width="2" />
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
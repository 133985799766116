<template>
    <header
        id="header"
        ref="head"
        :class="`fixed md:relative top-0 left-0 !z-[100] w-full max-w-[2000px] md:h-[114px] pb-3 mx-auto bg-white rounded-b-lg transition-shadow duration-300 flex justify-center ${scrolled ? 'shadow-lg' : ''} md:shadow-none`"
    >
        <router-link
            to="/"
            aria-label="Home"
            class="logo w-max mt-4 md:mt-0 md:absolute z-40 order-1 md:order-2"
        >
            <div
                class="md:bg-white md:p-5 md:rounded-lg md:h-max w-14 md:w-[85px] lg:w-[103px] box-content"
            >
                <MSLogo class="translate-x-[2px] relative z-10 [&>svg]:!w-full" :class="`${scrolledLogo ? 'logo--header' : ''} duration-75`" />
            </div>
        </router-link>
        <div id="landscape--menu--nav"
            :class="`bg-white   grid place-content-center md:flex md:items-center md:gap-4  transition-shadow duration-300  absolute right-4 translate-y-[calc(-100%_-_0.75rem)] md:fixed top-20 md:top-[114px] md:right-auto md:left-[calc(var(--padding-x-lg)*2)] md:bottom-4   h-max px-4 py-2 -ml-4  rounded-full`"
        >
            <router-link id="landscape--menu--logo" to="/" class="w-5 hidden md:block">
                <MSLogoSm />
            </router-link>
            <div id="landscape--menu--divider" class="bg-gray w-px h-5 hidden md:block"></div>
            <button id="landscape--menu--burger"
                @click="activeMenu = !activeMenu"
                type="button"
                title="menu"
                class="btn has_icon absolute md:static"
            >
                <Burger class="w-5" />
            </button>
        </div>
        <IconsBar :scrolled :auth="isLoggedIn" @openOffcanvas="openProductOffcanvas = true"/>
    </header>
    <template v-if="activeMenu">
        <Menu @close="() => (activeMenu = !activeMenu)" :activeMenu :auth="isLoggedIn" :navigation :staticLinks/>
    </template>
  <FormOffcanvas v-if="openProductOffcanvas" @close="openProductOffcanvas = false" :opened="openProductOffcanvas"/>
</template>

<script setup lang="ts">
import Burger from "@/icons/Burger.vue";

import IconsBar from "./components/IconsBar/IconsBar.vue";
import Menu from "./components/Menu/Menu.vue";


import {ref, onMounted, computed, watch} from "vue";

import MSLogoSm from "@/icons/MSLogoSm.vue";
import MSLogo from "@/icons/MSLogo.vue";
import FormOffcanvas from "@/includes/Header/components/IconsBar/FormOffcanvas.vue";
import { useUserStore } from "@/stores/userStore";
import {AccessNavData} from "@/util/accesser";
import type {NavigationItem} from "@/types";

const openProductOffcanvas = ref(false);

const activeMenu = ref(false);

const scrolled = ref(false);
const scrolledLogo = ref(false);

const userStore = useUserStore();

const isLoggedIn = computed(() => userStore.isLoggedIn);

const navigation = ref<NavigationItem[]>([]);
const staticLinks = ref<NavigationItem[]>([]);

AccessNavData(navigation, staticLinks);

watch(() => activeMenu, () => {
  AccessNavData(navigation, staticLinks);
})

onMounted(() => {
  if(window.innerWidth < 768) {
    scrolled.value = true;
    scrolledLogo.value = false;
  }
  window.addEventListener("scroll", () => {
    if (window.scrollY > 0 || window.innerWidth < 768) {
      if(window.scrollY === 0 && window.innerWidth < 768) {
        scrolledLogo.value = false;
      } else {
        scrolledLogo.value = true;
        scrolled.value = true;
      }
    } else {
        scrolled.value = false;
    }
  });
});
</script>

<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="21.5" viewBox="0 0 16.999 21.5">
            <path id="Pfad_40376" data-name="Pfad 40376"
                d="M15.75,6A3.75,3.75,0,1,1,12,2.25,3.75,3.75,0,0,1,15.75,6ZM4.5,20.118a7.5,7.5,0,1,1,15,0,18.047,18.047,0,0,1-15,0Z"
                transform="translate(-3.501 -1.25)" fill="none" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
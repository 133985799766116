<template>
  <section>
    <div class="flex flex-col items-center justify-center ">
      <h1 v-if="data.title" :class="`text-primary h2-s mt-0 mb-8`">{{ data.title }}</h1>
      <div v-if="data.teaserText" class="layout layout-content-start layout-content-end lg:full lg:full-end bg-gray rounded-lg py-7 px-0 lg:py-10 lg:px-4 2xl:px-6 2xl:grid-cols-[3%_1fr_3%]">
        <div ref="content" class="layout-content-start layout-content-end 2xl:col-end-3 text-gray-500 content [&>p:first-child]:inline [&_h2]:h2-s [&_h1]:h1-s [&_h2]:text-primary [&_h1]:text-primary [&_p]:!mt-2 mx-auto" v-html="getDate(data) + ' | ' + data.teaserText"></div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import moment from "moment";

const props = defineProps<{
  data: any;
}>();

function getDate(data: any) {
  if(data.postDate['date'] != null) {
    return moment(data.postDate['date']).format('DD.MM.YY');
  } else {
    return moment(data.postDate).format('DD.MM.YY');
  }
}
</script>
<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.848 24.847">
        <path id="Differenzmenge_3" data-name="Differenzmenge 3" d="M22.081,23.847H1.761A1.743,1.743,0,0,1,0,22.125V1.719A1.742,1.742,0,0,1,1.761,0h20.32a1.745,1.745,0,0,1,1.767,1.718V22.125A1.747,1.747,0,0,1,22.081,23.847ZM14.949,11.769c1.838,0,1.838,1.744,1.838,3.017V20.32H20.32V14.078c0-2.815-.514-5.42-4.241-5.42A3.733,3.733,0,0,0,12.732,10.5h-.049V8.94H9.292V20.32h3.536V14.691C12.827,13.246,13.079,11.769,14.949,11.769ZM3.534,8.94V20.32h3.54V8.94ZM5.3,3.283a2.05,2.05,0,1,0,2.05,2.05A2.052,2.052,0,0,0,5.3,3.283Z" transform="translate(0.5 0.5)" fill="currentColor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
export function FormatLink(link: string): string {
    if(link === "__home__" ) {
        return "/";
    } else {
        return `/${link}`;
    }
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.044 24.872">
            <path id="Pfad_40374" data-name="Pfad 40374"
                d="M19.978,25.958V22.241l-3.763,3.717V22.241L12.5,25.958H.682V16.665L16.216,1.132V16.666L31.726,1.132V26l-11.747-.045"
                transform="translate(-0.682 -1.132)" fill="#e30613" />
        </svg>
        <span class="hidden">startseite</span>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
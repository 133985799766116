import { User } from '@/types/user'
import { defineStore } from 'pinia'

type StateType = {
    loading: boolean
    user: User | null
    loggedIn: boolean
}

export const useUserStore = defineStore('user', {
    state: () => ({ loading: true, user: null, loggedIn: false } as StateType),
    actions: {
        setUser(user: User) {
            if (!user) return;


            this.user = {
                id: user.id,
                email: user.email,
                salutation: user.salutation,
                firstName: user.firstName,
                lastName: user.lastName,
                addresses: {
                    title: user.addresses ? user.addresses.title : "",
                    addressLine2: user.addresses ? user.addresses.addressLine2 : "",
                    addressLine1: user.addresses ? user.addresses.addressLine1 : "",
                    postalCode: user.addresses ? user.addresses.postalCode : "",
                    country: user.addresses ? user.addresses.country : "",
                    locality: user.addresses ? user.addresses.locality : "",
                    phoneNumber: user.addresses ? user.addresses.phoneNumber : "",
                    mobilePhone: user.addresses ? user.addresses.mobilePhone : "",
                },
                hasProperty: user.hasProperty,
                hasNewsletterAbo: user.hasNewsletterAbo,
                photo: null,
                fullName: user.fullName,
            };
            this.loading = false;
            this.loggedIn = true;
        },
        clearUser() {
            this.user = null;
        }
    },
    getters: {
        isLoggedIn() {
            return this.loggedIn
        },
        isLoading() {
            return this.loading
        },
        getUserData() {
            return this.user;
        }
    }
}
)
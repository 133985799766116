<template>
  <div class="space-y-4">
    <div class="bg-gray px-6 py-10 rounded-lg">
      <div>
        <img v-if="isLoggedIn && user && user.photo"
          class="w-1/2 aspect-square rounded-full object-cover" :src="user.photo.url" />
        <h3 v-if="user && user.firstName && user.lastName" class="h4 my-2 pb-4 leading-6">
          {{ user.firstName }} <br />{{user.lastName }}
        </h3>
        <v-btn to="/user/settings">{{ getTranslationByKey('Profil bearbeiten') }}</v-btn>
      </div>
      <hr class="border-white my-8 -mx-6" />
      <form class="mt-4" accept-charset="UTF-8">
        <input type="hidden" name="action" value="users/logout" />
        <input type="hidden" :name="csrfName" :value="csrfToken" />
        <v-btn type="submit">{{ getTranslationByKey('Abmelden') }}</v-btn>
      </form>
    </div>
    <!-- <div
            class="bg-gray px-6 py-10 rounded-lg min-h-[25vh] flex flex-col gap-6"
        >
            <h3>
                <v-icon icon="mdi-calendar-multiselect-outline" />
                Ihre nächsten Termine
            </h4>
            <ul>
                <li>
                    <b>Besichtigung Musterhaus</b>
                    <p>15.01.2024, 10:00 Uhr</p>
                </li>
            </ul>
            <a href="/user" class="text-primary underline text-sm mt-auto">
                Zur Terminverwaltung
            </a>
        </div> -->
    <div class="bg-gray px-6 py-10 rounded-lg">
      <router-link to="/user/downloads" class="flex items-center gap-4 hover:text-primary">
        <h3>
          <v-icon icon="mdi-download" />
          {{ getTranslationByKey('Ihre Downloads') }}
        </h3>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { User } from "@/types/user";

import { useUserStore } from "@/stores/userStore";
import {getTranslationByKey} from "@/stores/translation";

const userStore = useUserStore();

const user = computed(() => userStore.getUserData as User);
const isLoggedIn = computed(() => userStore.isLoggedIn);

const csrfName = ref("");
const csrfToken = ref("");

onMounted(() => {
  csrfName.value = window.csrfTokenName;
  csrfToken.value = window.csrfTokenValue;
});
</script>

<template>
  <Offcanvas @close="Close" :active="SubMenuState.active" position="left">
    <template #content="{ close }">
      <div class="w-screen flex">
        <div
          class="relative z-40 bg-gray p-6 lg:p-10 min-h-[100dvh] h-max flex flex-col w-full md:w-[50vw] md:max-w-[460px]">
          <ActionBar @close="close" />
          <Nav @linkClicked="close" @subMenu="SetState" :activeSubMenu="SubMenuState.active" :active="SubMenuState.active" :auth :navigation :static-links/>
        </div>
        <SubMenu @linkClicked="close" @close="CloseSubMenu()" :state="SubMenuState" />
      </div>
      <div @click="close" class="absolute top-0 w-full h-full z-10">
      </div>
    </template>
  </Offcanvas>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ActionBar from './ActionBar.vue'
import Nav from './Nav.vue'
import SubMenu from './SubMenu.vue';
import Offcanvas from '@/snippets/Offcanvas.vue';
import type {NavigationItem} from "@/types";

const props = defineProps<{
  navigation: NavigationItem[],
  staticLinks: NavigationItem[],
  auth: boolean
}>();

const emit = defineEmits(['close'])
const SubMenuState = ref({
  active: false,
  links: []
})

function Close() {
  emit('close');
}

function SetState(e) {
  SubMenuState.value.links = e;
  SubMenuState.value.active = true;
}

function CloseSubMenu() {
  SubMenuState.value.links = [];
  SubMenuState.value.active = false;
}

</script>
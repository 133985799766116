<template>
  <picture>
    <template
      v-if="props.srcset"
      v-for="(src, index) in parseSrcset(props.srcset)"
      :key="index"
    >
      <source :srcset="src.path" :media="`(max-width: ${src.width}px)`" />
    </template>
    <img
      :class="`${props.class} ${objectFit ? 'object-contain' : 'object-cover'}`"
      class="pointer-events-none"
      :style="` ${
        img?.hasFocalPoint
          ? `object-position: ${img?.focalPoint
              .map((entry) => (entry * 100).toFixed(2).toString() + '% ')
              .join(' ')}`
          : ''
      } `"
      :src="props.src && props.src?.includes('://') ? props.src : origin + props.src"
      :alt="props.alt ? props.alt : ''"
      :title="props.title ? props.title : ''"
      :loading="props.isNotLazy ? 'eager' : 'lazy'"
      :fetchpriority="props.isNotLazy ? 'high' : 'low'"
    />
  </picture>
</template>

<script setup lang="ts">
import { Image } from "@/types/elements";
import { computed } from "vue";
import { useHead } from "@unhead/vue";

const props = defineProps<{
  src: string;
  srcset?: string;
  alt?: string;
  title?: string;
  class: string;
  objectFit?: boolean | false;
  img?: Image;
  isNotLazy?: boolean;
}>();

const origin = location.origin;

function parseSrcset(srcset: string): { path: string; width: number }[] {
  return srcset.split(",").map((entry) => {
    if (!entry) return;
    const [path, width] = entry.trim().split(" ");
    return { path, width: parseInt(width.replace("w", "")) };
  });
}

const preloadHref = computed(() => {
  if (props.srcset) {
    const parsedSrcset = parseSrcset(props.srcset);
    if (parsedSrcset.length > 0) {
      return parsedSrcset[0].path;
    }
  }
  return props.src;
});

if (props.isNotLazy) {
  useHead({
    link: [
      {
        rel: "preload",
        as: "image",
        href: preloadHref.value,
        fetchpriority: "high",
      },
    ],
  });
}
</script>

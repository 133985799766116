<template>
  <section id="houseResults" ref="startElement" class="lg:full lg:full-end scroll-mt-[8.75rem] md:scroll-mt-10">
    <div class="grid grid-cols-1 gap-10 py-10 lg:grid-cols-3 lg:gap-6">
      <template v-if="loading">
        <div v-for="i in 9" class="skeleton aspect-[1.3] rounded-lg"></div>
      </template>
      <template v-if="products.length > 0 && !loading">
        <CardWithTags
          v-for="entry in products"
          :url="entry.uri"
          :id="entry.id"
          :heading="entry.title"
          :img="
            entry.houseHeaderImage && entry.houseHeaderImage[0]
              ? entry.houseHeaderImage[0]
              : entry.houseGallery[0]
          "
          :houseStyles="
            entry.houseStyles.length > 0 && entry.houseStyles[0].title
              ? entry.houseStyles[0].title
              : ''
          "
          :realEstateTypes="
            entry.realEstateTypes.length > 0 && entry.realEstateTypes[0].title
              ? entry.realEstateTypes[0].title
              : ''
          "
          :totalLivingArea="entry.totalLivingArea ? entry.totalLivingArea : 0"
          :promotionalPrice="entry.promotionalPrice"
        />
      </template>
      <template v-else>
        <div class="text-center col-span-3">
          <p class="text-2xl" v-html="`${getTranslationByKey('Keine Häuser gefunden')}`"></p>
        </div>
      </template>
    </div>
    <div>
      <!-- add one more page because we do not have page 0 -->
      <v-pagination v-model="activePage" :length="calcLength()"></v-pagination>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useGoTo } from "vuetify/lib/framework.mjs";

import CardWithTags from "@/components/Card/CardWithTags.vue";

import client from "@/api/craft";
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { products, active_query, total, settings, activePage, loading } from "../store";
import {getTranslationByKey} from "@/stores/translation";

// set all api functions

const router = useRouter();
const route = useRoute();
const goTo = useGoTo();

// html elements
const startElement = ref<null | HTMLElement>(null);

// setting
const maxPerPage = settings.perPage;

activePage.value = parseInt(route.query.page as string) || activePage.value;

// watch for page change
watch(activePage, () => {
  UpdatePagination();
});

// update pagination
function UpdatePagination(initial: boolean = false) {
  loading.value = true;
  // clear the state
  products.value = [];

  // define limit and offset for query
  const limit = maxPerPage;
  const offset = (activePage.value - 1) * maxPerPage;

  router.push({ ...route, query: { ...route.query, page: activePage.value } });

  client.Pagination(active_query.value, limit, offset).then((res) => {
    products.value = res.data.houseEntries;
    loading.value = false;
  });

  // scroll to wrappers top
  if (!initial) {
    goTo(startElement.value, {
      duration: 500,
      easing: "easeInOutQuart",
      offset: -40,
    });
  }
}

function calcLength() {
  if (total.value % maxPerPage == 0) {
    return Math.floor(total.value / maxPerPage);
  } else {
    return Math.floor(total.value / maxPerPage) + 1;
  }
}
</script>

<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 20">
            <path id="Pfad_40378" data-name="Pfad 40378"
                d="M20.25,8.511a2.192,2.192,0,0,1,1.5,2.1v4.286a2.178,2.178,0,0,1-1.98,2.193c-.34.027-.68.052-1.02.072V20.25l-3-3q-2.031,0-4.02-.163a2.115,2.115,0,0,1-.825-.242M20.25,8.511a2.126,2.126,0,0,0-.476-.1,48.64,48.64,0,0,0-8.048,0A2.177,2.177,0,0,0,9.75,10.608v4.286A2.207,2.207,0,0,0,10.9,16.845M20.25,8.511V6.637A3.218,3.218,0,0,0,17.49,3.4a48.63,48.63,0,0,0-12.48,0A3.218,3.218,0,0,0,2.25,6.637v6.226A3.218,3.218,0,0,0,5.01,16.1q.866.113,1.74.194V21l4.155-4.155"
                transform="translate(-1.25 -2)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" />
        </svg>

    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
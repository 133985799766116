<template>
  <div class="active-offcanvas fixed w-full h-full z-[100] top-0 right-0">
    <!-- :class="active ? 'left-0 active-offcanvas' : 'left-[-100%]'" -->
    <div
      :class="{
        'left-0': animated && position == 'left',
        '-left-full': !animated && position == 'left',
        'right-0': animated && position == 'right',
        '-right-full': !animated && position == 'right',
      }"
      class="fixed w-max h-screen !h-[100dvh] transition-all top-0 duration-500 overflow-y-scroll scrollbar-hide [&:has(.pls-no-scroll)]:overflow-y-hidden z-50"
    >
      <slot name="content" :close="Close" />
    </div>
    <div
      @click="Close"
      :class="animated ? 'opacity-30' : 'opacity-0'"
      class="absolute w-full h-full bg-black transition-opacity duration-300"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
  active: Boolean;
  position: "left" | "right";
}>();

const emit = defineEmits(["close"]);

const animated = ref(false);

function Close() {
  document.getElementById("openProductoffcanvas")?.setAttribute('data-show', "false");
  animated.value = false;
  setTimeout(() => {
    emit("close");
  }, 500);
}

onMounted(() => {
  setTimeout(() => {
    animated.value = true;
  }, 1);
});

watch(
  () => props.active,
  () => {
    if (props.active === true) {
      animated.value = true;
    }
  }
);
</script>

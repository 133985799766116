<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157.836 86.22">
        <g id="Gruppe_6202" data-name="Gruppe 6202" transform="translate(1.5)">
          <text id="_360_" data-name="360°" transform="translate(21.216)" fill="#fff" font-size="50" font-family="OpenSans-Bold, Open Sans" font-weight="700"><tspan x="12.513" y="53">360°</tspan></text>
          <path id="Pfad_40465" data-name="Pfad 40465" d="M148.154,34.422c31.836-2.711,55.022-11.047,55.022-20.914,0-4.224-4.254-8.167-11.613-11.509" transform="translate(-48.34 45.731)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <path id="Pfad_40466" data-name="Pfad 40466" d="M13.613,2C6.254,5.342,2,9.285,2,13.51c0,9.866,23.186,18.2,55.022,20.913" transform="translate(-2 45.731)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <line id="Linie_184" data-name="Linie 184" y1="3.988" x2="11.194" transform="translate(44.297 80.316)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <line id="Linie_185" data-name="Linie 185" x1="9.868" y1="6.62" transform="translate(45.628 73.648)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <line id="Linie_186" data-name="Linie 186" x1="11.194" y1="3.988" transform="translate(99.015 80.316)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          <line id="Linie_187" data-name="Linie 187" y1="6.62" x2="9.868" transform="translate(99.009 73.648)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>

    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
<template>
    <section :class="`full full-end layout -mr-[var(--padding-x-lg)] marginTop-standard marginBottom-standard`">
        <div :class="`grid ${ heading && contentText ? 'lg:grid-cols-2' : '' } items-center gap-4 xl:gap-8 mb-4`">
            <h2
                v-if="heading"
                class="h2-s text-primary mt-0 text-2xl"
                v-html="heading"
            ></h2>
            <p
                v-if="contentText"
                class="pb-6"
                v-html="contentText"
            ></p>
        </div>
        <div class="full full-end sm:layout-content-start lg:full pagination-bottom">
            <SwiperPagination
                v-if="references && references.length > 0"
                :actionButton="true"
                :slidesPerView="slides"
                class="pb-8 lg:pl-[var(--padding-x-lg)] normal"
                :spaceBetween="10"
                action-link="/" :arrows="true"
                :length="references.length">
                <template v-for="item in references">
                    <swiper-slide
                        v-if="item"
                        class="pb-8 layout sm:not-layout lg:pr-3"
                    >
                        <CardWithTags
                            :img="item.houseGallery[0]"
                            :url="item.uri"
                            :heading="item.title"
                            :id="typeof +item.id === 'number' ? +item.id : 0"
                            :houseStyles="item.houseStyles.length > 0 && item.houseStyles[0].title? item.houseStyles[0].title:''"
                            :realEstateTypes="item.realEstateTypes.length > 0 && item.realEstateTypes[0].title? item.realEstateTypes[0].title:''"
                            :totalLivingArea="item.totalLivingArea? item.totalLivingArea:0"
                            :promotionalPrice="item.promotionalPrice"
                        />
                    </swiper-slide>
                </template>
            </SwiperPagination>
        </div>
    </section>
</template>

<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import { onMounted, ref } from "vue";
import type { Block } from "@/types";

import CardWithTags from "@/components/Card/CardWithTags.vue";
import SwiperPagination from "@/components/Slider/SwiperPagination.vue";

const props = defineProps<{
    heading?: string;
    contentText?: string;
    references: any[];
}>();

const slides = ref(3.2);

onMounted(() => {
    setValue();
    window.addEventListener("resize", setValue);
});

function setValue() {
    const main = document.querySelector("main");
    const { width } = main.getBoundingClientRect();
    if (width >= 2299) {
        slides.value = 4;
    } else if(width >= 1280) {
        slides.value = 3.2;
    } else if (width >= 1024) {
        slides.value = 2.2;
    } else if (width >= 640) { 
        slides.value = 1.9;
    } else {
        slides.value = 1;
    }
}

</script>

<template>
    <router-link :to="url" class="w-full h-full relative bg-gray rounded-lg block lg:aspect-[1.2]">
        <div v-if="props.image && props.image[0]"
            class="w-full lg:h-full rounded-lg relative z-0 after:bg-gradient-to-t after:absolute after:top-0 after:rounded-lg after:left-0 after:w-full after:h-full after:opacity-30 after:from-[#000] after:to-[transparent]">
            <Image class="w-full rounded-lg object-cover aspect-[1.2]" :src="props.image[0].url"
                :srcset="props.image[0].srcset" :img="props.image[0]"
                :alt="props.image[0].alt ? props.image[0].alt : ''"
                :title="props.image[0].title ? props.image[0].title : ''" :isNotLazy="false" />
        </div>
        <div class="p-4 bg-[transparent] lg:absolute lg:bottom-0 lg:text-white">
            <h4 class="bg-[transparent] text-sm">
                {{ props.title }}
            </h4>
        </div>
    </router-link>
    <div class="swiper-lazy-preloader"></div>
</template>

<script setup lang="ts">

import type { Image } from "@/types/elements";

const props = defineProps<{
    title: string;
    image: Image[];
    url: string;
}>();
</script>
<template>
    <div style="--swiper-navigation-color: #E30613" class="relative lg:scale-100 swiper-pagination-section hero">
        <swiper :navigation="true" :pagination="pagination" @swiper="Init" @slideChange="Init" :modules="modules"
            :slides-per-view="props.slidesPerView ? props.slidesPerView : 1"
            :space-between="props.spaceBetween ? props.spaceBetween : 0" :class="props.class">
            <slot />
            <v-btn v-if="actionLink?.url && paginationEl"
                :to="actionLink.isElement ? FormatLink(actionLink.element.uri) : actionLink.url" aria-label="Link"
                :style="`left: calc(103% + ${paginationWidth}px + var(--space-between))`"
                class="action-btn hero-action hidden lg:block">{{ actionLink.text }}</v-btn>
        </swiper>
    </div>
    <div class="flex justify-end gap-2 py-0 pr-2 md:hidden">
        <button v-on:click="sw.slidePrev()" :disabled="start" :class="start ? 'opacity-50 cursor-not-allowed' : ''"
            class="scale-100 opacity-100 grid place-content-center aspect-square w-12 rounded-full transition-all duration-300 active:scale-[0.90]"
            type="button" :aria-label="getTranslationByKey('Vorherige Folie')">
            <ArrowLeftCircleIcon class="w-12 text-primary" />
        </button>
        <button v-on:click="sw.slideNext()" :disabled="end" :class="end ? 'opacity-50 cursor-not-allowed' : ''"
            class="scale-100 opacity-100 grid place-content-center aspect-square w-12 rounded-full transition-all duration-300 active:scale-[0.90]"
            type="button" :aria-label="getTranslationByKey('Nächste Folie')">
            <ArrowRightCircleIcon class="w-12 text-primary" />
        </button>
    </div>
</template>

<script setup lang="ts">
// TODO: responsive design

// Import Swiper Vue.js components
import { Swiper } from "swiper/vue";

// Import Swiper styles
// import "swiper/css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { ref, onMounted } from "vue";
import { waitForElm } from "@/util/await";

// import types
import { Link } from "@/types/elements";

// import formater
import { FormatLink } from "@/util/formater";

// import icons
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/vue/24/outline";
import {getTranslationByKey} from "@/stores/translation";

const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
        return `<span data-index="${index}" class="slider-bullet ${className}"></span>`;
    },
};

const props = defineProps<{
    slidesPerView: number;
    spaceBetween: number;
    class: string;
    actionButton: boolean;
    actionLink: Link | null
    paginationPosition?: string;
}>();

const end = ref(false);
const start = ref(true);

const paginationEl = ref<HTMLElement | null>(null);
const swiperEl = ref<HTMLElement | null>(null);
const sw = ref<any>(null);

const paginationWidth = ref(0);

const active = ref(0);
const bullets = ref(0);

const modules = [Pagination, Navigation];

let bulletwidth = 10;
let marginL = 20;
let margin = 6;
let activeWidth = 30;

let bullet = bulletwidth + margin * 2;
let activeBullet = bulletwidth + margin + marginL;

onMounted(() => {
    window.addEventListener("resize", calculatePosition);
    paginationWidth.value = bullet * (bullets.value - 1) + activeBullet;
});

function Init(e) {
    // e is swiper event => https://swiperjs.com/vue#swiper-events
    sw.value = e;
    active.value = e.activeIndex;
    bullets.value = e.slides.length;
    paginationEl.value = e.pagination.el;
    swiperEl.value = e.el;

    if (e.isEnd) {
        end.value = true;
        start.value = false;
    } else if (e.isBeginning) {
        start.value = true;
        end.value = false;
    } else {
        start.value = false;
        end.value = false;
    }

    calculatePosition();
}

function calculatePosition() {

    let width = paginationEl.value.getBoundingClientRect().width;

    // it looks terable but it works.
    // first we the width of full without bullets and divide it by 2, so it is a coordinate of the left border
    // active bullet has defferent margin, so we need to calculate it separately
    let bulletsWidth = (width - (bullet * (bullets.value - 1) + activeBullet)) / 2;

    // we know the position of lefts border, so we can calculate the position of active bullet between bullets
    let activeBulletPosition =
        active.value == 0 ? 0 : active.value * bullet;

    // calculate the position of active bullet
    let left = (bulletsWidth < 0 ? 0 : bulletsWidth) + activeBulletPosition;

    // set active bullet position
    swiperEl.value.removeAttribute("style");
    swiperEl.value.setAttribute(
        "style",
        `--active-bullet-left: ${left}px; --active-bullet-left-margin: ${marginL}px; --swiper-pagination-bullet-horizontal-gap: ${margin}px; --bullet-width: ${bulletwidth}px; --active-width: ${activeWidth}px`
    );
}
</script>

<style lang="scss"></style>
<template>
    <div class="w-full flex items-center relative z-30">
        <router-link to="/" @click="emit('close')" class="mr-5">
          <MSLogoSm class="w-6" />
        </router-link>
        <v-divider vertical></v-divider>
        <router-link to="/search" @click="emit('close')" class="ml-5">
          <Search class="w-4 h-4" />
        </router-link>
        <v-select-icon :items="languages" v-model="selectedLanguage" class="ml-5">
          <template #selection>
            <Globe class="w-4 h-4" />
          </template>
        </v-select-icon>
        <button @click="emit('close')" type="button" title="close" class="ml-auto">
            <Close class="w-4 h-4" />
        </button>
    </div>
</template>

<script setup lang="ts">
import Close from '@/icons/Close.vue'
import Search from '@/icons/Search.vue'
import MSLogoSm from "@/icons/MSLogoSm.vue";
import Globe from "@/icons/Globe.vue";
import {ref, watch} from "vue";
import {isCurrentEnglish} from "@/stores/siteid";

// Import the environment variable
const PRIMARY_SITE_URL = import.meta.env.VITE_PRIMARY_SITE_URL;
const SECONDARY_SITE_URL = import.meta.env.VITE_SECONDARY_SITE_URL;

const emit = defineEmits(['close']);

const languages = isCurrentEnglish() ? ['German', 'English'] : ['Deutsch', 'Englisch'];
const selectedLanguage = isCurrentEnglish() ? ref(languages[1]) : ref(languages[0]);


watch(selectedLanguage, (newLanguage) => {
  changeLanguage(newLanguage);
});
function changeLanguage(newLanguage: string) {
  if(newLanguage === 'Englisch') {
    location.href = SECONDARY_SITE_URL;
  } else {
    location.href = PRIMARY_SITE_URL;
  }
}

</script>

<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.849 14.85">
            <g id="Gruppe_2231" data-name="Gruppe 2231" transform="translate(-1.075 3.688)">
                <line id="Linie_144" data-name="Linie 144" x1="16.999" y2="0.001"
                    transform="translate(14.51 9.747) rotate(-135)" fill="none" stroke="#1b2125" stroke-linecap="round"
                    stroke-width="2" />
                <line id="Linie_145" data-name="Linie 145" x1="16.999" y1="0.001"
                    transform="translate(14.51 -2.274) rotate(135)" fill="none" stroke="#1b2125" stroke-linecap="round"
                    stroke-width="2" />
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
import { ref } from 'vue';
import type { WishList } from '@/types';

const wishlist = ref<{
    wishlistId: number;
    wishlistItems: string[];
}>({
    wishlistId: 0,
    wishlistItems: []
});

export default wishlist;
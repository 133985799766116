<template>
  <Filter :heading="heading" :img="heroImage"/>
  <Products />
  <!-- <Newsletter /> -->
</template>

<script setup lang="ts">
import Filter from "./components/Filter.vue";
import Products from "./components/Products.vue";
import { loaded } from "@/stores/loading";
import {onMounted, ref} from "vue";
import { useHead } from "@unhead/vue";
import {seoData, seoImg} from "@/api/queries";
import client from "@/api/craft";
import type { Image } from "@/types/elements";

const seo = ref(<seoData>{
  metaDescription: "Finden Sie Ihr Meisterstück",
  metaTitle: "Hausfinder | Meisterstück-HAUS",
  globalMetaImage: {'url': '/files/images/SEO/MS-logo.jpg', 'width': '322px', 'height': '139px', 'format': 'jpg'}
});
let heading = ref<string>("");
let heroImage = ref<Image[]>();


getData();
onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

async function getData() {
  let dataResults = await client.GetHousefinder();
  let globalData = await client.GetGeneralGlobals();

  if(dataResults && dataResults.data.entries.length > 0) {
    seo.value = {
      metaTitle: dataResults.data.entries[0].metaTitle ?? seo.value.metaTitle,
      metaDescription: dataResults.data.entries[0].metaDescription ?? seo.value.metaDescription,
      metaImage: dataResults.data.entries[0].metaImage,
    }
  }
  if(globalData && globalData.data.globalSet && globalData.data.globalSet.metaImage) {
    seo.value.globalMetaImage = globalData.data.globalSet.metaImage[0];
  }

  heading.value = dataResults.data.entries[0].heading ?? null;
  heroImage.value = dataResults.data.entries[0].img ?? null;
}

useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format)
              : (seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format),
    },
    {
      property: "og:image:width",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width)
              : (seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width),
    },
    {
      property: "og:image:height",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height)
              : (seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height),
    },
    {
      name: "twitter:image",
      content: () =>
          seo.value && seo.value.metaImage && seo.value.metaImage[0] && seo.value.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>
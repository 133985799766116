<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.811 9.621">
            <path id="Pfad_40390" data-name="Pfad 40390" d="M17.25,8.25,21,12m0,0-3.75,3.75M21,12H3"
                transform="translate(-2.25 -7.189)" fill="none" stroke="#E30613" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.5" />
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
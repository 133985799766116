export const footerQuery = `
query GetHouseEntries($siteId: [QueryArgument]) {
    globalSet(handle: "footer", siteId: $siteId) {
      ... on footer_GlobalSet {
        id
        name
        footerLogo {
          value
          url
          type
          label
          keywords
          isEmpty
          inline
        }
        footerNavigation {
          ... on footerNavigation_linkGroup_BlockType {
            id
            footerHeading
            children {
              ... on footerNavigation_link_BlockType {
                id
                typeHandle
                footerLink {
                  isElement
                  isEmpty
                  element {
                    uri
                  }
                  url
                  target
                  newWindow
                  linkValue
                  linkUrl
                  linkText
                }
              }
              ... on footerNavigation_icons_BlockType {
                id
                typeHandle
                footerIcons {
                  ... on footerIcons_BlockType {
                    id
                    icon {
                      value
                      url
                      type
                      label
                      keywords
                      isEmpty
                      inline
                    }
                    iconLink {
                      isElement
                      isEmpty
                      element {
                        uri
                      }
                      url
                      target
                      newWindow
                      linkValue
                      linkUrl
                      linkText
                    }
                  }
                }
              }
              ... on footerNavigation_text_BlockType {
                id
                typeHandle
                footerText
              }
            }
          }
        }
        footerServiceNavigation {
         ... on footerServiceNavigation_linkGroup_BlockType {
          id
          children {
            ... on footerServiceNavigation_link_BlockType {
               id
              typeHandle
              footerLink {
                isElement
                isEmpty
                element {
                  uri
                }
                url
                target
                newWindow
                linkValue
                linkUrl
                linkText
              }
            }
          }
        }
      }
    }
  }  
}
`
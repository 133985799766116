import { ref } from "vue";

export const products = ref([]);

export const active_query = ref("");
export const activePage = ref(1);
export const totalPreview = ref(0);
export const total = ref(0);
export const loading = ref(true);

export const settings = {
    perPage: 12,
}
<template>
    <section class="grid place-content-center h-[70vh] h-[70dvh]">
        <h1 class="text-center">404</h1>
        <p>{{ getTranslationByKey('Die Seite konnte nicht gefunden werden') }}</p>
    </section>
</template>

<script setup lang="ts">

import { onMounted } from 'vue';
import { loaded } from '@/stores/loading';
import {getTranslationByKey} from "@/stores/translation";

onMounted(() => {
    loaded.value.animation = true;
    setTimeout(() => {
        loaded.value.active = false;
    }, 300);
});

</script>
<template>
    <div :class="props.class">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.846 23.847">
        <path id="Pfad_40361" data-name="Pfad 40361" d="M138.466,115.936H117.252a1.316,1.316,0,0,0-1.316,1.316v21.214a1.316,1.316,0,0,0,1.316,1.316h11.421v-9.235h-3.108v-3.6h3.108v-2.654c0-3.08,1.881-4.757,4.629-4.757a25.415,25.415,0,0,1,2.777.142V122.9h-1.906c-1.494,0-1.784.71-1.784,1.752v2.3h3.564l-.464,3.6h-3.1v9.235h6.077a1.316,1.316,0,0,0,1.316-1.316V117.252A1.316,1.316,0,0,0,138.466,115.936Z" transform="translate(-115.936 -115.936)" fill="currentColor"></path>
      </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
<template>
  <div
    :class="`full full-end layout w-full lg:full lg:full-end relative z-0 aspect-[1.8] sm:aspect-[2] xl:aspect-[2.5]`"
  >
    <div
      :class="
        block?.Referenzen && block?.Referenzen[0] && block?.Referenzen[0].entry.length > 0
          ? 'aspect-square sm:aspect-[2] lg:aspect-[2.2]'
          : 'h-full'
      "
      class="absolute hero full full-end top-0 left-0 w-full bg-[#EAEAEA] rounded-lg"
    >
      <Image
        v-if="block.img && block.img[0]"
        :src="block.img[0].url"
        :srcset="block.img[0].srcset"
        :class="`w-full h-full opacity-90 rounded-lg object-cover `"
        :img="block.img[0]"
        :alt="block.img[0].alt ? block.img[0].alt : ''"
        :title="block.img[0].title ? block.img[0].title : ''"
        :isNotLazy="index === 0 ? true : false"
      />
    </div>
    <div class="relative z-10 text-white py-16 md:py-20 lg:py-24 xl:py-40">
      <h1
        v-if="block?.heading && block?.isH1"
        v-text="block.heading"
        style="filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.5))"
        :class="`shadow-black ${block?.headingSize == 'normal' ? 'h1' : 'h1-s'}`"
      ></h1>
      <h2
        v-else-if="block?.heading && !block?.isH1"
        v-text="block.heading"
        style="filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.5))"
        :class="`shadow-black ${block?.headingSize == 'normal' ? 'h1' : 'h1-s'}`"
      ></h2>
      <h2
        v-if="block?.preheading"
        v-text="block.preheading"
        style="filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.5))"
        class="font-bold my-3 drop-shadow-2xl shadow-black text-xl lg:text-3xl xl:text-4xl"
      ></h2>
    </div>
  </div>
  <div
    v-if="
      block?.Referenzen &&
      block?.Referenzen.length > 0 &&
      block?.Referenzen[0].entry.length > 0 &&
      block?.img &&
      block?.img[0]
    "
    class="relative block z-20 layout-content-start full-end sm:-mt-[100px] xl:mt-0 w-full lg:w-[55%] lg:pagination-right"
  >
    <SliderHero :action="props.block.contentLink[0]" :items="block.Referenzen" />
  </div>
</template>

<script setup lang="ts">
import { BlockHero } from "@/types/components/Hero";

const props = defineProps<{
  block: BlockHero;
  index: number;
}>();
</script>

<template>
  <Offcanvas @close="Close" :active="active" position="right">
    <template #content="{ close }">
      <div
        ref="wrapper"
        class="lg:relative lg:z-40 bg-gray min-h-[100dvh] h-max overflow-y-auto flex flex-col relative w-screen lg:w-[40vw] lg:min-w-[600px] login"
      >
        <button @click="close()" class="w-6 absolute top-8 right-6 lg:top-14 lg:right-12">
          <XMarkIcon class="w-full" />
        </button>
        <button
          v-if="tabName != 'login'"
          @click="tabName = 'login'"
          class="w-6 absolute top-14 right-6 lg:top-20 lg:right-12"
        >
          <ArrowLeftIcon class="w-full" />
        </button>
        <LoginForm
          @change="(x) => (tabName = x)"
          v-if="tabName == 'login'"
          :registerSuccess="registerSuccess"
        />
        <RegisterForm
          @success="
            (x) => {
              tabName = x.tab;
              registerSuccess = x.registerSuccess;
            }
          "
          v-if="tabName == 'register'"
        />
        <ResetPassword v-if="tabName == 'reset'" />
      </div>
    </template>
  </Offcanvas>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { ref, watch } from "vue";

import Offcanvas from "@/snippets/Offcanvas.vue";

import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
import ResetPassword from "./ResetPassword.vue";

import { openLogin } from "@/stores/login";

const wrapper = ref<HTMLElement | null>(null);

const registerSuccess = ref(openLogin.value.registerSuccess ?? false);

const emit = defineEmits(["close"]);
const props = defineProps<{
  active: Boolean;
  register: Boolean;
}>();

const tabName = ref<"login" | "register" | "reset">(
  props.register ? "register" : "login"
);

watch(
  () => tabName.value,
  () => {
    if (wrapper.value) {
      wrapper.value.scrollTop = 0;
    }
  }
);

function Close() {
  emit("close");
  openLogin.value.register = false;
}
</script>

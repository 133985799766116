<template>
    <div :class="props.class">
        <svg class="w-full h-full" id="Komponente_187_1" data-name="Komponente 187 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.105 116.105">
            <rect id="Rechteck_649" data-name="Rechteck 649" width="116.105" height="116.105" rx="58.053"
                fill="#efefef" />
            <path id="Vereinigungsmenge_7" data-name="Vereinigungsmenge 7"
                d="M0,66.346V58.054c0-9.125,14.928-16.589,33.173-16.589s33.173,7.464,33.173,16.589v8.292ZM16.585,16.588A16.586,16.586,0,1,1,33.173,33.173,16.588,16.588,0,0,1,16.585,16.588Z"
                transform="translate(24.88 21.562)" fill="currentColor" />
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
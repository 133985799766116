<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20.75">
            <g id="Gruppe_5886" data-name="Gruppe 5886" transform="translate(-3.5 -2)">
                <path id="Pfad_40380" data-name="Pfad 40380" d="M15,10.5a3,3,0,1,1-3-3A3,3,0,0,1,15,10.5Z" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Pfad_40381" data-name="Pfad 40381"
                    d="M19.5,10.5c0,7.142-7.5,11.25-7.5,11.25S4.5,17.642,4.5,10.5a7.5,7.5,0,0,1,15,0Z" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>

    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
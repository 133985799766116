<template>
    <div :class="props.class">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15">
            <g id="Gruppe_2231" data-name="Gruppe 2231" transform="translate(1 1)">
                <line id="Linie_144" data-name="Linie 144" x1="17" fill="none" stroke="#1b2125" stroke-linecap="round"
                    stroke-width="2" />
                <line id="Linie_145" data-name="Linie 145" x1="17" transform="translate(0 6.5)" fill="none"
                    stroke="#1b2125" stroke-linecap="round" stroke-width="2" />
                <line id="Linie_146" data-name="Linie 146" x1="11" transform="translate(0 13)" fill="none"
                    stroke="#1b2125" stroke-linecap="round" stroke-width="2" />
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["class"])

</script>
import { ref } from 'vue';

const errorStore = ref<{
    active: boolean;
    message: string;
}>({
    active: false,
    message: ''
});

export default errorStore;
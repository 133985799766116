<template>
  <footer
    id="footer"
    class="layout self-end h-max mt-36 w-full mx-auto bg-gray rounded-t-lg md:pb-10 lg:pb-20 lg:bg-[transparent] relative z-10"
  >
    <div
      class="hidden lg:block bg-gray full full-end absolute top-0 w-full h-full rounded-t-lg"
    ></div>
    <div class="relative grid-rows-2 max-md:translate-y-[-5%]">
      <CustomerSupport :logo />
      <Infos :navigation :serviceNavigation :auth="isLoggedIn" />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { FooterNavigation } from "@/types";
import { FooterServiceNavigation } from "@/types";
import CustomerSupport from "./components/CustomerSupport.vue";
import Infos from "./components/Infos.vue";
import { AccessFooterData } from "@/util/accesser";
import { computed, onMounted, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { openLogin } from "@/stores/login";

const navigation = ref<FooterNavigation[]>(null);
const serviceNavigation = ref<FooterServiceNavigation[]>(null);
const logo = ref(null);
const userStore = useUserStore();
const isLoggedIn = computed(() => userStore.isLoggedIn);

onMounted(() => {
  if (!isLoggedIn) {
    let footer = document.querySelector("footer");

    if (footer) {
      let community = footer.querySelectorAll('[href="/user"]');

      if (community) {
        community.forEach((item) => {
          item.addEventListener("click", (e) => {
            e.preventDefault();
            openLogin.value.open = true;
          });
        });
      }
    }
  }

  AccessFooterData(navigation, serviceNavigation, logo);
});
</script>

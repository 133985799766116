<template>
    <section data-target ref="section" v-intersect="(e, s, t) => onInteresecting(e, s)" :class="className">
        <slot />
    </section>
</template>

<script setup lang="ts">
import { loaded } from '@/stores/loading';
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps<{
    className?: string;
    index?: number;
}>();

const section = ref<HTMLElement | null>(null);

function onInteresecting(isIntersecting: boolean, observer: any) {
  if(!section.value) return;
    observer[0].boundingClientRect.top <= 0 ? section.value.classList.add("under") : section.value.classList.remove("under");
    isIntersecting ? section.value.classList.add("animate") : section.value.classList.remove("animate");
}

onMounted(() => {
    if (props.index === 0) {
        loaded.value.animation = true;
        setTimeout(() => {
            loaded.value.active = false;
        }, 300);
    };
});
</script>
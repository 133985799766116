<template>
    <div>
        <h1>{{ getTranslationByKey('Nachrichten') }}</h1>
    </div>
</template>

<script setup lang="ts">

import { loaded } from '@/stores/loading';
import { onMounted } from 'vue';
import {getTranslationByKey} from "@/stores/translation";

onMounted(() => {
    loaded.value.animation = true;
    setTimeout(() => {
        loaded.value.active = false;
    }, 300);
});

</script>

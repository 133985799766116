import { ref } from "vue";

const translation = ref<Array<any>>([]);

export function setTranslationTable(table: Array<any>): void {
    translation.value = table;
}

export function getTranslations(): Array<any> {
    return translation.value;
}

export function getTranslationByKey(key: string): string {
    if(translation.value[key]) {
        return translation.value[key];
    } else {
        return key;
    }
}
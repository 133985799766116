import { ref } from "vue";
import {News} from "@/types";

const news = ref<{
    hash: string;
    news: News[];
}>({
    hash: '',
    News: []
});

export default news;
import { ref } from "vue";

const siteid = ref(1);

export function setSiteId(): void {
    if(window.location.pathname.includes("/eng") || window.location.hostname.endsWith("com")){
        siteid.value = 2;
    } else {
        siteid.value = 1;
    }
}

export function getSiteId(): number | null {
    return siteid.value;
}

export function isCurrentEnglish(): boolean {
    return siteid.value === 2;
}
import { ref } from "vue";
import {Appointments} from "@/types";

const appointments = ref<{
    hash: string;
    appointments: Appointments[];
}>({
    hash: '',
    Appointments: []
});

export default appointments;
<template>
  <template v-if="initial">
    <slot />
  </template>
  <template v-else-if="house && !loading">
    <Hero @openOffcanvas="openProductOffcanvas = true" :imgs="house.houseGallery" :house="house" />
    <Details :house="house" />
    <Video :youtube-id="house.videoId" />
    <HouseTour v-if="house.houseTour && house.houseTour[0]" :house-tour="house.houseTour[0]"/>
    <CardsSlider v-if="referenceHouses.length > 0" :heading="getTranslationByKey('Diese Häuser könnten Sie auch interessieren:')" :references="referenceHouses.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)" />
    <ProductOffcanvas v-if="openProductOffcanvas" @close="openProductOffcanvas = false" :opened="openProductOffcanvas" :houseTitle="house.title"/>
    <ActionBar @openOffcanvas="openProductOffcanvas = true" :house="house" />
  </template>
  <template v-else-if="!loading">
    <Error />
  </template>
  <template v-else>
    <section class="full full-end skeleton lg:full lg:full-end rounded-lg mb-7">
      <div class="full full-end lg:full lg:full-end aspect-[1.8] sm:aspect-[2.8] xl:aspect-[3.55]"></div>
    </section>
    <div class="flex flex-col gap-5 mt-4 lg:flex-row lg:gap-24">
      <div class="w-full skeleton h-64 rounded-lg lg:h-96"></div>
      <div class="w-full skeleton h-64 rounded-lg lg:h-96"></div>
    </div>
  </template>
  
  <!-- <CardsSlider title="Diese Häuser könnten Sie auch interessieren:" /> -->

</template>

<script setup lang="ts">
import client from "@/api/craft";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import type { HouseEntry } from "@/types";

import wishlist from "@/stores/wishlist";
import errorStore from "@/stores/error";
import { loaded } from "@/stores/loading";

import craft from "@/api/craft";
import ProductOffcanvas from "@/pages/House/components/ProductOffcanvas.vue";

import Hero from "@/pages/House/components/Hero/Hero.vue";
import Details from "@/pages/House/components/Details/Details.vue";
import CardsSlider from "./components/References/CardsSlider.vue";
import ActionBar from "./components/Action/ActionBar.vue";
import Error from "@/components/Error/Error.vue";
import Video from "@/pages/House/components/Video/Video.vue";
import Newsletter from "@/components/ActionBaner/Newsletter.vue";
import HouseTour from "@/pages/House/components/Details/components/HouseTour.vue";
import {useHead} from "@unhead/vue";
import {seoData} from "@/api/queries";
import {getTranslationByKey} from "@/stores/translation";
import initial from "@/stores/initial";

const openProductOffcanvas = ref(false);

const props = defineProps<{
  auth: boolean;
  user?: any;
}>();

const route = useRoute();
const house = ref<HouseEntry | null>(null);
const referenceHouses = ref<HouseEntry[]>([]);
const loading = ref(true);
const seo = ref(<seoData>{
  metaDescription: "Ihr Meisterstück",
  metaTitle: "Ihr Meisterstück | Meisterstück-HAUS",
  globalMetaImage: {'url': '/files/images/SEO/MS-logo.jpg', 'width': '322px', 'height': '139px', 'format': 'jpg'}
});

client.GetHouse(route.params.slug as string)
  .then((res) => {
    if (res && res.data && res.data.houseEntries && res.data.houseEntries.length > 0) {
      house.value = res.data.houseEntries[0];
      getSEOData(house.value);
      loading.value = false;
      client.Pagination(`houseStyles: [${house.value.houseStyles.map((entry) => entry.id).join(",")}], slug: "!= ${route.params.slug}"`, 9, 0)
        .then((res) => {
          referenceHouses.value = res.data.houseEntries;
        })
    } else {
      loading.value = false;
    }
  });

onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

async function getSEOData(house: HouseEntry) {
  let globalData = await client.GetGeneralGlobals();

  if(house) {
    let description = '';
    if(house.metaDescription) {
      description = house.metaDescription;
    } else {
      if(house.description_house.length > 252) {
        description = house.description_house.substring(0, 251) + '...';
      } else {
        description = house.description_house;
      }
      description = description.replace(/<[^>]*>/g, '');
    }

    seo.value = {
      metaTitle: house.metaTitle ?? house.title + " | Meisterstück-HAUS",
      metaDescription: description,
      metaImage: house.metaImage,
      globalMetaImage: globalData.data.globalSet.metaImage[0],
    }
  }
}

useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format)
              : (seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format),
    },
    {
      property: "og:image:width",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width)
              : (seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width),
    },
    {
      property: "og:image:height",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height)
              : (seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height),
    },
    {
      name: "twitter:image",
      content: () =>
          seo.value && seo.value.metaImage && seo.value.metaImage[0] && seo.value.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>
import { ref } from "vue";
import { User } from "@/types/user";

import { seoData } from "@/api/queries";

export class userClass {
    /*
    Downloads API
    --------------------------------------------------------------------------------
    */
    public async getDownloads() {
        try {
            const res = fetch("/api/user/downloads", {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            return (await res).json();
        } catch(error) {
            console.error(error);
            return {
                success: false,
                downloads: []
            }
        }
    }

    /*
    Get current user
    --------------------------------------------------------------------------------
    */

    public async GetMe() {
        try {
            const res = await fetch('/api/user/me', { 
                method: "get", 
                headers: { 
                    'Content-Type': 'application/json', 
                    "Accept": "application/json" 
                } 
            })

            return {
                success: true,
                user: (await res.json()).user as User
            };
        } catch(error) {
            return {
                success: false,
                user: null
            }
        }
    }
}
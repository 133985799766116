import { createRouter, createWebHistory } from "vue-router";

import Notifications from "../pages/User/pages/Notifications/index.vue";
import Settings from "../pages/User/pages/Settings/index.vue";
import Overview from "../pages/User/pages/Overview/index.vue";
import Downloads from "../pages/User/pages/Downloads/index.vue";

import Home from "../pages/Blocks/Index.vue";
import Finder from "../pages/Finder/index.vue";
import Wishlist from "../pages/Wishlist/index.vue";
import Search from "../pages/Search/index.vue";
import Newsletter from "../pages/Newsletter/index.vue";
import House from "../pages/House/index.vue";
import News from "../pages/News/index.vue";
import Appointment from "../pages/Appointment/index.vue";
import User from "../pages/User/index.vue";

const routes = [
  // single routes
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/hausfinder",
    name: "Finder",
    component: Finder,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
  },
  // content routes
  {
    path: "/haus/:slug",
    name: "House",
    component: House,
  },
  {
    path: "/news/:pathMatch(.*)*",
    name: "News",
    component: News,
  },
  {
    path: "/termin/:pathMatch(.*)*",
    name: "Appointment",
    component: Appointment,
  },

  // user routes
  {
    path: "/user",
    name: "User",
    component: User,
    children: [
      // dynamic import didn't work here
      {
        path: "overview",
        name: "UserOverview",
        component: Overview,
      },
      {
        path: "settings",
        name: "UserSettings",
        component: Settings,
      },
      {
        path: "notifications",
        name: "UserNotifications",
        component: Notifications,
      },
      {
        path: "downloads",
        name: "UserDownloads",
        component: Downloads,
      },
    ],
  },

  // all routes
  {
    path: "/:pathMatch(.*)*",
    name: "Page",
    component: Home,
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  history: createWebHistory(),
  routes,
});

export default router;

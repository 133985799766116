<template>
  <div id="actionbarMenu" :style="`bottom: ${bottom}px`" ref="actions"
       class="duration-200 fixed md:hidden action-bar !z-10 flex justify-between items-center px-4 py-2 left-1/2 -translate-x-1/2 w-[90%] bg-gray rounded-full shadow-lg"
       :class="show ? 'opacity-100 blur-none' : 'opacity-0 blur-sm'">
    <div class="w-10 aspect-square relative rounded-full bg-primary">
        <router-link :to="isCurrentEnglish() ? '/house-designs' : '/hausfinder'" class="w-[55%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :aria-label="`${getTranslationByKey('Zum Hausfinder')}`">
          <Hause class="w-full"/>
        </router-link>
    </div>
    <div class="w-10 aspect-square relative rounded-full" v-if="isLoggedIn">
      <router-link  to="/user/downloads" class="w-[55%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :aria-label="`${getTranslationByKey('Zum Katalog')}`">
        <BookOpenIcon class="w-full stroke-2"/>
      </router-link>
    </div>
<!--    <div class="w-10 aspect-square relative rounded-full">-->
    <div class="hover:scale-110" :class="`w-10 aspect-square relative rounded-full transition-opacity duration-300`">
          <router-link to="/wishlist" class="w-[55%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :aria-label="`${getTranslationByKey('Zur Merkliste')}`">
            <div class="w-full relative" :data-items-length="wishlist.wishlistItems.length"
                :class="`${wishlist.wishlistItems.length > 0 ? 'after:absolute after:w-4 after:grid after:place-content-center after:aspect-square after:bg-black after:top-[-40%] after:right-[-60%] after:rounded-full after:content-[attr(data-items-length)] after:text-white after:text-xs' : ''}`">
              <Heart class="w-full stroke-2" />
            </div>
          </router-link>
    </div>
    <div v-if="!isCurrentEnglish()" class="w-10 aspect-square relative rounded-full">
      <button @click="openLogin.open = true" type="button" class="w-[55%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :aria-label="`${getTranslationByKey('Zum Kontobereich')}`">
        <Profile class="w-full"/>
      </button>
    </div>
    <div class="w-10 aspect-square relative rounded-full bg-primary">
        <button @click="emit('openOffcanvas')" class="w-[55%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :aria-label="`${getTranslationByKey('Kontakt')}`">
          <Chat class="w-full" />
        </button>
    </div>
  </div>
  
</template>

<script setup lang="ts">
import { BookOpenIcon } from "@heroicons/vue/24/outline";
import Hause from "@/icons/Hause.vue";
import Profile from "@/icons/Profile.vue";
import Chat from "@/icons/Chat.vue";
import { openLogin } from "@/stores/login";
import wishlist from "@/stores/wishlist";

import {watch, ref, onMounted, computed} from "vue";
import {useRoute} from "vue-router";
import { useUserStore } from "@/stores/userStore";
import {getTranslationByKey} from "@/stores/translation";
import { isCurrentEnglish } from "@/stores/siteid";

const userStore = useUserStore();

const isLoggedIn = computed(() => userStore.isLoggedIn);

const route = useRoute();

const actions = ref<HTMLElement | null>(null);
const houseActions = ref<HTMLElement | null>(null);

const emit = defineEmits(["openOffcanvas"]);

// set bottom value
const bottom = 20;

const isHouse = ref(false);

watch(() => route.name, (newId, oldId) => {
  isHouse.value = route.path.includes("haus");
})

watch(() => houseActions.value, () => {
  setBottomValue();
})

let show = ref(window.innerWidth < 768);
window.addEventListener('scroll', hideOnFooter);
window.onresize = () => (show.value = window.innerWidth < 768);

onMounted(() => {
  window.addEventListener("resize", setBottomValue);
});

function hideOnFooter() {
  let footerOffset = document.getElementById("footer").offsetTop - window.innerHeight - 75;
  if (window.innerWidth > 768 || window.scrollY > footerOffset) {
    show.value = false;
  } else {
    show.value = true;
  }
}

function setBottomValue() {
  if (actions.value === null || houseActions.value === null) return;

  const height = actions.value.getBoundingClientRect().height;

  // calculate actionBar height with doubled bottom value
  const calculetedBottom = height + (bottom * 2);

  houseActions.value.style.setProperty("bottom", `${calculetedBottom}px`);
}
</script>